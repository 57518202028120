import React, { Component, useState } from 'react';
import { getRecordById, getRecords, updateRecord } from '@app/utils/APIUtils';
import { Form, Input, Select } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;
const { TextArea } = Input;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  return (
    <EditCategory
      {...props}
      navigation={navigate}
      isLoading={isLoading}
      setLoading={setLoading}
      params={params}
      form={form}
    />
  );
}

class EditCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      categoryId: this.props.params.id,
      parentCategoryList: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
  }

  componentDidMount() {
    let promise;

    promise = getRecords('/service/v1/categories/all');

    this.setState({
      isLoading: true,
    });

    let promise1 = getRecordById('/service/v1/categories/', this.state.categoryId);
    promise1.then((res) => {
      this.props.form.setFieldsValue({
        categoryName: res.CategoryName,
        parentCategoryId: res.ParentID,
        description: res.Desc,
      });

      promise
        .then((response) => {
          this.setState({
            parentCategoryList: response.map((item) => ({ value: item.id, label: item.CategoryName })),
          });
        })
        .catch((error) => {
          if (error)
            this.setState({
              isLoading: false,
            });
        });
    });
    Promise.all([promise, promise1]);
  }

  handleSubmit(values) {
    const updateRequest = Object.assign({}, values);

    updateRecord(this.state.categoryId, updateRequest, '/service/v1/categories/')
      .then((response) => {
        if (response)
          notificationController.success({
            message: 'Category updated',
          });

        this.props.navigation('/category/list');
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigation('/category/list');
  }

  render() {
    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="category" title="Category Edit" padding="1.25rem">
          <Form
            form={this.props.form}
            onFinish={this.handleSubmit}
            className="category-form"
            name="category"
            labelCol={{ span: 6 }}
            //wrapperCol={{ span: 16 }}
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem
              label="Parent Category"
              name="parentCategoryId"
              rules={[
                {
                  required: true,
                  message: 'Parent category required!',
                },
              ]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select"
                size="small"
                onKeyDown={(e) => handleEnter(e)}
                optionFilterProp="children"
                onSearch={onSearch}
              >
                {this.state.parentCategoryList.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              label="Category Name"
              name="categoryName"
              rules={[
                {
                  required: true,
                  message: 'Category name required!',
                },
              ]}
              className="ant-latest-form-item"
            >
              <Input
                size="small"
                autoComplete="off"
                placeholder="Enter category name"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem
              label="Description"
              name="description"
              rules={[{ required: false, message: 'Description is required!' }]}
              className="ant-latest-form-item"
            >
              <TextArea
                autoComplete="off"
                size="small"
                placeholder="Enter descriptions"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem
              wrapperCol={{
                offset: 6,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }
}
