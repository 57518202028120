import React, { Component, useState } from 'react';
import { Radio, Select } from 'antd';
import { getRecords } from '@app/utils/APIUtils';
import { ACCESS_TOKEN, API_BASE_URL } from '@app/constants';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  return <CustomerHotelReport {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} />;
}

class CustomerHotelReport extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(CustomerHotelReportForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class CustomerHotelReportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      hotelId: {
        value: '',
      },
      hotelList: [],
      packageId: {
        value: '',
      },
      pkgList: [],
      isLoading: false,
      response: {},
      fileFormat: {
        value: '',
      },
      hajYearList: [],
      allianceList: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handlePackageChange = this.handlePackageChange.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise, promise1, promise2, promise3;

    promise = getRecords('/setup/v1/hotels/list/all');
    promise1 = getRecords('/setup/v1/packages/list/all');
    promise2 = getRecords('/domain/data/hajyears/v1/active/all');
    promise3 = getRecords('/admin/v1/agents/user/alliances');

    this.setState({
      isLoading: true,
    });

    promise
      .then((response) => {
        this.setState({
          hotelList: response.map((item) => ({ value: item.id, label: item.HotelName })),
        });
        console.log(this.state.hotelList);
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    promise1
      .then((response) => {
        this.setState({
          pkgList: response.map((item) => ({ value: item.id, label: item.PackageName })),
        });
        console.log(this.state.pkgList);
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    promise2
    .then((response) => {
      this.setState({
        hajYearList: response.map((item) => ({ value: item.id, label: item.ceYear + ' - (' + item.hijriYear + ')' })),
      });
    })
    .catch((error) => {
      if (error)
        this.setState({
          isLoading: false,
        });
    });


    promise3
    .then((response) => {
      this.setState({
        allianceList: response.map((item) => ({ value: item.dataPath, label: item.agentName })),
      });
    })
    .catch((error) => {
      if (error)
        this.setState({
          isLoading: false,
        });
    });

    Promise.all([promise, promise1, promise2, promise3]);
  }

  handlePackageChange = (value) => {
    try {
      getRecords(`/setup/v1/hotels/${value}/package`)
        .then((response) => {
          this.setState({
            hotelList: response.map((item) => ({ value: item.id, label: item.HotelName })),
          });
        })
        .catch((error) => {
          if (error)
            this.setState({
              hotelList: [],
            });
        });
    } catch (error) {}
  };



  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const paramRequest = Object.assign({}, values);
        console.log('paramRequest => ' + JSON.stringify(paramRequest));

        fetch(API_BASE_URL + '/setup/v1/hotels/preview', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
          },
          body: JSON.stringify(paramRequest),
        })
          .then(async function (response) {
            const filename = response.headers
              .get('content-disposition')
              .split(';')
              .find((n) => n.includes('filename='))
              .replace('filename=', '')
              .replace('"', '')
              .replace('"', '')
              .trim();
            console.log(filename);
            if (response.ok) {
              let blob2;
              let blob;
              var fileExt = filename.split('.').pop();

              const reportBytes = await response.arrayBuffer();

              if (fileExt === 'pdf') {
                blob2 = new File([new Uint8Array(reportBytes)], 'CustomersByHotel.pdf', { type: 'application/pdf' });
                blob = new Blob([reportBytes], { type: 'application/pdf' });
              } else if (fileExt === 'xls') {
                blob2 = new File([new Uint8Array(reportBytes)], 'CustomersByHotel.xls', {
                  type: 'application/vnd.ms-excel',
                });
                blob = new Blob([reportBytes], { type: 'application/vnd.ms-excel' });
              }
              //const FileSaver = require('file-saver');
              //const blob2 =  new File([new Uint8Array(reportBytes)], 'EmployeeInformationReport.pdf', {type: "application/pdf"})
              //const blob = new Blob([reportBytes], { type: "application/pdf"});
              const reportUrl = URL.createObjectURL(blob2);
              const win = window.open(reportUrl, 'test');
              win.document.write(
                '<iframe src="' +
                  reportUrl +
                  '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
              );
              win.document.src = filename;

              win.history.pushState(null, null, null);
              //win.print()
              // win.document.filename = filename;
              //win.document.filename = filename;
              //FileSaver.saveAs(blob, filename);
              notificationController.success({
                message: 'Report generated successfully',
              });
              console.log(blob);
              console.log(response.body);
              console.log(reportBytes);
              console.log(blob2);
              console.log(reportUrl);
            }
          })
          .catch(function (error) {
            console.log('Request failed', error);
          });
      }
    });
  }

  clearForm(event) {
    event.preventDefault();
    this.props.form.resetFields();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Reports > List of Customers By Hotel" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="report-form"
            name="basic"
            labelCol={{ span: 7 }}
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem label="Filtering Criteria"></FormItem>

            <FormItem label="Hajj Year">
              {getFieldDecorator('hajYearId', {
                initialValue : this.state.hajYearList.map((item) => (item.value))[0],
                rules: [{ required: true, message: 'Hajj year required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select Hajj Year"
                  name="hajYearId"
                  size="small"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.hajYearList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Agent / Sub-agent">
              {getFieldDecorator('allianceDataPath', {
                rules: [{ required: false, message: 'Alliance is required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select Alliance"
                  name="allianceDataPath"
                  size="small"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                  defaultValue={0}
                >
                  <Select.Option value={0}>ALL</Select.Option>
                  {this.state.allianceList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Package">
              {getFieldDecorator('packageId', {
                rules: [{ required: false, message: 'Package is required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select package"
                  size="small"
                  name="packageId"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  defaultValue={0}
                  onChange={this.handlePackageChange}
                >
                  <Select.Option value={0}>ALL</Select.Option>
                  {this.state.pkgList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Hotel">
              {getFieldDecorator('hotelId', {
                rules: [{ required: false, message: 'Hotel is required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select package"
                  size="small"
                  name="hotelId"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  defaultValue={0}
                >
                  <Select.Option value={0}>ALL</Select.Option>
                  {this.state.hotelList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Export Option">
              {getFieldDecorator('fileFormat', {
                rules: [{ required: true, message: 'Please select report format!' }],
              })(
                <Radio.Group name="fileFormat">
                  <Radio value="pdf">PDF</Radio>
                  <Radio value="xls">XLS</Radio>
                </Radio.Group>,
              )}
            </FormItem>

            <FormItem
              wrapperCol={{
                offset: 7,
                span: 9,
              }}
            >
              <BaseRow gutter={[10, 10]} wrap={false}>
                <BaseCol span={12} xs={17} sm={12} xl={12}>
                  <BaseButton block type="primary" htmlType="submit" size="small">
                    Submit
                  </BaseButton>
                </BaseCol>
                <BaseCol span={12} xs={17} sm={12} xl={12}>
                  <BaseButton block type="ghost" onClick={this.clearForm} size="small">
                    Reset
                  </BaseButton>
                </BaseCol>
              </BaseRow>
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }
}
