import React, { Component, useState } from 'react';
import { saveRecord, searchRecord, getRecords } from '@app/utils/APIUtils';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  return (
    <CustomerFlightAdd
      {...props}
      navigation={navigate}
      isLoading={isLoading}
      setLoading={setLoading}
      current={current}
      setCurrent={setCurrent}
    />
  );
}

class CustomerFlightAdd extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(CustomerFlightAddForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
          current={this.props.current}
          setCurrent={this.props.setCurrent}
        />
      </div>
    );
  }
}

class CustomerFlightAddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      agentId: {
        value: '',
      },
      customerName: {
        value: '',
      },
      mobileNumber: {
        value: '',
      },
      passportNo: {
        value: '',
      },
      nationalId: {
        value: '',
      },
      groupNumber: {
        value: '',
      },
      tags: {
        value: '',
      },
      durationId: {
        value: '',
      },
      agentList: [],
      durationList: [],
      packageList: [],
      isReadonly: false,
      isRequired: false,
      recordSet: [],
      response: {},
      customerId: [],
      flightId: [],
      flightRecSet: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    //this.handleAgentChange = this.handleAgentChange.bind(this);
  }

  // handlePageChange = (newpage) => {

  //     this.props.setLoading(true)

  //     setTimeout(() => {
  //         this.props.setLoading(false)
  //     }, 1000);

  //     this.props.setPage(newpage);

  // };

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise, promise1, promise2, promise3;

    promise = getRecords('/admin/v1/agents/all');

    this.setState({
      isLoading: true,
    });

    promise
      .then((response) => {
        this.setState({
          agentList: response.map((item) => ({ value: item.id, label: item.AgentName })),
        });
        console.log(this.state.agentList);
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    promise1 = getRecords("/domain/data/durations/v1/all");
    promise1.then(response => {
      this.setState({
        durationList:response.map(item=> ({value:item.id, label:item.NoOfDays})),
          
      });
    }).catch(error => {
      notificationController.error({
        message: 'Failed! ' + error.message,
      });
      this.setState({
          isLoading: false
      })
    });


    promise3 = getRecords('/setup/v1/packages/list/all');
    promise3
    .then((response) => {
      this.setState({
        packageList: response.map((item) => ({ value: item.id, label: item.PackageName })),
      });
      console.log(this.state.packageList);
    })
    .catch((error) => {
      notificationController.error({
        message: 'Failed! ' + error.message,
      });
      this.setState({
        isLoading: false,
      });
    });


    promise2 = getRecords("/setup/v1/flights/all");
    promise2.then(response => {
      this.setState({
        flightRecSet:response,
          
      });
    }).catch(error => {
      notificationController.error({
        message: 'Failed! ' + error.message,
      });
      this.setState({
          isLoading: false
      })
    });

    Promise.all([promise, promise1, promise2, promise3]);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        //const saveRequest = Object.assign({}, values);
        const saveRequest = {
          customerId: this.state.customerId,
          flightId: this.state.flightId,
        };
        console.log('saveRequest => ' + JSON.stringify(saveRequest));
        if (
          values.customerName === undefined &&
          values.mobileNumber === undefined &&
          values.groupNumber === undefined &&
          values.nationalId === undefined &&
          values.passportNo === undefined &&
          values.tags === undefined &&
          values.durationId === undefined &&
          values.pkgId === undefined
        ) {
          notificationController.warning({
            message: 'Please enter one search criteria!',
          });
        } else {
          if (Object.keys(saveRequest.customerId).length === 0) {
            notificationController.warning({
              message: 'Please select any customer!',
            });
          } else if (Object.keys(saveRequest.flightId).length === 0) {
            notificationController.warning({
              message: 'Please select flight!',
            });
          } else {
            saveRecord(saveRequest, '/customer/v1/flights')
              .then((response) => {
                if (response.statusCode === 100) {
                  notificationController.success({
                    message: 'Flight assigned.',
                  });
                  this.props.navigate('/assign_customer_flight/list');
                } else if (response.statusCode === 101) {
                  notificationController.warning({
                    message: 'Flight already assigned.',
                  });
                }
              })
              .catch((error) => {
                notificationController.error({
                  message: 'Failed! ' + error.message,
                });
              });
          }
        }
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/assign_customer_flight/list');
  }

  handleSearch(event) {
    event.preventDefault();
    this.props.form.validateFields((_err, values) => {
      if (
        values.customerName === undefined &&
        values.mobileNumber === undefined &&
        values.groupNumber === undefined &&
        values.nationalId === undefined &&
        values.passportNo === undefined &&
        values.tags === undefined &&
        values.durationId === undefined &&
        values.pkgId === undefined 
      ) {
        notificationController.warning({
          message: 'Please enter one search criteria!',
        });
      }
      const searchRequest = {
        customerName: values.customerName === '' ? null : values.customerName,
        mobileNumber: values.mobileNumber === '' ? null : values.mobileNumber,
        groupNumber: values.groupNumber === '' ? null : values.groupNumber,
        nationalId: values.nationalId === '' ? null : values.nationalId,
        passportNo: values.passportNo === '' ? null : values.passportNo,
        tags: values.tags === '' ? null : values.tags,
        durationId: values.durationId === '' ? null : values.durationId,
        pkgId: values.pkgId === '' ? null : values.pkgId,
      };
      console.log(values);
      //const searchRequest = Object.assign({}, values);
      console.log('searchRequest => ' + JSON.stringify(searchRequest));

      searchRecord(searchRequest, '/customer/v1/flights/search/customer')
        .then((response) => {
          this.setState({
            recordSet: response,
          });
          setTimeout(() => {
            this.props.setLoading(false);
          }, 1000);
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
        });
    });
  }

  isFormInvalid() {
    return !(this.state.agentName.validateStatus === 'success');
  }

  clearForm(event) {
    event.preventDefault();
    this.props.form.resetFields();
  }

  handleAgentChange = (value) => {
    try {
      getRecords(`/setup/v1/flights/${value}/agent`)
        .then((response) => {
          this.setState({
            flightRecSet: response,
          });
        })
        .catch((error) => {
          if (error)
            this.setState({
              flightRecSet: [],
            });
        });
    } catch (error) {}
  };

  render() {
    const { recordSet, flightRecSet } = this.state;
    const { getFieldDecorator } = this.props.form;

    const customerSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          customerId: selectedRows.map((item) => item.customerId),
        });

        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
    };

    const flightSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          flightId: selectedRows.map((item) => item.flightId),
        });

        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
    };

    const data = [];
    const flightData = [];

    //Customer record set
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        customerId: record.customerId,
        customerName: record.customerName,
        packageName: record.packageName,
        passportNo: record.passportNo,
        duration: record.duration,
      });
    });

    //Flight record set
    flightRecSet.forEach((record, recordIndex) => {
      flightData.push({
        key: recordIndex,
        flightId: record.id,
        flightNumber: record.FlightNumber,
        destination: record.DepFrom + ' - ' + record.ArrivalTo,
        flightType: record.FlightType === 'D' ? 'Departure' : 'Return',
      });
    });

    const columns = [
      /* {
        title: 'ID',
        dataIndex: 'customerId',
        key: 'customerId',
      }, */
      {
        title: 'Name',
        dataIndex: 'customerName',
        key: 'customerName;',
      },
      {
        title: 'Package',
        dataIndex: 'packageName',
        key: 'packageName',
        width: '20%'
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
      },
      {
        title: 'Passport',
        dataIndex: 'passportNo',
        key: 'passportNo',
        width: '10%'
      },
    ];

    const flightColumns = [
     /*  {
        title: 'Flight ID',
        dataIndex: 'flightId',
        key: 'flightId',
        width:'20%',
      }, */
      {
        title: 'Flight Number',
        dataIndex: 'flightNumber',
        key: 'flightNumber;',
      },
      {
        title: 'Destination',
        dataIndex: 'destination',
        key: 'destination;',
      },
      {
        title: 'Flight Type',
        dataIndex: 'flightType',
        key: 'flightType;',
      },
    ];

    return (
      <BaseCard id="validation form" title="Customer Flight / Add" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <BaseCard id="mobileNumber" padding="1.25rem">
              <div style={{ marginBottom: 10 }}>
                <span style={{ color: '#01509A', fontWeight: 600 }}>Search Customer By (any one field)</span>
              </div>
              <Form
                onSubmit={this.handleSearch}
                className="search-form"
                name="basic"
                labelCol={{ span: 6 }}
                style={{ maxWidth: 500 }}
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <BaseRow gutter={[0, 0]}>
                  <BaseCol xs={24} sm={12} xl={12}>
                    <FormItem label="Mobile">
                      {getFieldDecorator('mobileNumber', {
                        rules: [{ required: false, message: 'Mobile number is required!' }],
                      })(
                        <Input
                          name="mobileNumber"
                          placeholder="Enter mobile number"
                          value={this.state.mobileNumber.value}
                        />,
                      )}
                    </FormItem>

                    <FormItem label="Name">
                      {getFieldDecorator('customerName', {
                        rules: [{ required: false, message: 'Customer name is required!' }],
                      })(
                        <Input
                          name="customerName"
                          placeholder="Enter customer name"
                          value={this.state.customerName.value}
                        />,
                      )}
                    </FormItem>

                  </BaseCol>

                  <BaseCol xs={24} sm={12} xl={12}>
                    <FormItem label="PP No">
                      {getFieldDecorator('passportNo', {
                        rules: [{ required: false, message: 'Passport no is required!' }],
                      })(
                        <Input
                          name="passportNo"
                          placeholder="Enter passport number"
                          value={this.state.passportNo.value}
                        />,
                      )}
                    </FormItem>

                    <FormItem label="Group">
                      {getFieldDecorator('groupNumber', {
                        rules: [{ required: false, message: 'Group no is required!' }],
                      })(
                        <Input
                          name="groupNumber"
                          placeholder="Enter group number"
                          value={this.state.groupNumber.value}
                        />,
                      )}
                    </FormItem>

                    {/* <FormItem label="NID ">
                      {getFieldDecorator('nationalId', {
                        rules: [{ required: false, message: 'NID is required!' }],
                      })(
                        <Input name="nationalId" placeholder="Enter national id" value={this.state.nationalId.value} />,
                      )}
                    </FormItem>

                    <FormItem label="Tags">
                      {getFieldDecorator('tags', {
                        rules: [{ required: false, message: 'Tags is required!' }],
                      })(
                        <Input
                          name="tags"
                          placeholder="Enter tags"
                          value={this.state.tags.value}
                        />,
                      )}
                    </FormItem> */}
                  </BaseCol>

                  <BaseCol xs={24} sm={24} xl={24}>
                    <FormItem label="Duration" labelCol={{ span: 0 }}>
                      {getFieldDecorator('durationId', {
                        rules: [{ required: false, message: 'Duration is required!' }],
                      })(
                        <Select
                          showSearch
                          placeholder="Select duration"
                          size="small"
                          name="durationId"
                          optionFilterProp="children"
                          onSearch={onSearch}
                        >
                          {this.state.durationList.map((item, index) => (
                            <Select.Option value={item.value} key={index}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                  </BaseCol>

                  <BaseCol xs={24} sm={24} xl={24}>
                    <FormItem label="Package" labelCol={{ span: 0 }}>
                      {getFieldDecorator('pkgId', {
                        rules: [{ required: false, message: 'Package is required!' }],
                      })(
                        <Select
                          showSearch
                          placeholder="Select package"
                          size="small"
                          name="pkgId"
                          optionFilterProp="children"
                          onSearch={onSearch}
                        >
                          {this.state.packageList.map((item, index) => (
                            <Select.Option value={item.value} key={index}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                  </BaseCol>

                  <BaseCol xs={24} sm={12} xl={12}>
                    <FormItem>
                      <BaseRow gutter={[10, 10]} wrap={false} style={{ justifyContent: 'space-between' }}>
                        <BaseCol span={12} xs={12} sm={12} xl={12}>
                          <BaseButton block type="primary" htmlType="submit" size="small">
                            Search
                          </BaseButton>
                        </BaseCol>
                        <BaseCol span={12} xs={12} sm={12} xl={12}>
                          <BaseButton block type="ghost" onClick={this.clearForm} size="small">
                            Reset
                          </BaseButton>
                        </BaseCol>
                      </BaseRow>
                    </FormItem>
                  </BaseCol>
                </BaseRow>
              </Form>

              {/*Table*/}
              <div className="page-Container" style={{ marginTop: 20 }}>
                <BaseTable
                  rowSelection={{
                    type: 'checkbox',
                    ...customerSelection,
                  }}
                  columns={columns}
                  dataSource={data}
                  pagination={{ pageSize: 5 }}
                  scroll={{ x: true }}
                  rowKey={(record) => record.customerId}
                  bordered
                />
              </div>
            </BaseCard>
          </BaseCol>
          <BaseCol xs={24} sm={12} xl={12}>
            <Form
              onSubmit={this.handleSubmit}
              className="bus-form"
              name="basic"
              labelCol={{ span: 4 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              {/* <FormItem label="Agent">
                {getFieldDecorator('agentId', {
                  rules: [{ required: true, message: 'Agent is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select Agent"
                    size="small"
                    name="agentId"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onChange={this.handleAgentChange}
                  >
                    {this.state.agentList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem> */}

              <FormItem>
                <BaseCard id="validation form" padding="1.25rem" title="Flight List">
                  <div className="page-Container" style={{ marginTop: 20 }}>
                    <BaseTable
                      rowSelection={{
                        type: 'radio',
                        ...flightSelection,
                      }}
                      columns={flightColumns}
                      dataSource={flightData}
                      pagination={{ pageSize: 5 }}
                      scroll={{ x: true }}
                      rowKey={(record) => record.flightId}
                      bordered
                    />
                  </div>
                </BaseCard>
              </FormItem>
            </Form>
          </BaseCol>
        </BaseRow>
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            wrapperCol={{
              offset: 9,
              span: 6,
              style: { marginTop: 25 },
            }}
          >
            <BaseButtonsGroup onCancel={this.onCancelForm} />
          </FormItem>
        </Form>
      </BaseCard>
    );
  }
}
