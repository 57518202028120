import React, { useState, useEffect } from 'react';
import { getRecordById, updateRecord, getRecords } from '@app/utils/APIUtils';
import { Input, Select, Form } from 'antd';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';

const FormItem = Form.Item;
const { TextArea } = Input;

export const EditService = () => {
  //const [loading, setLoading] = useState(false);
  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const [parentServiceList, setParentServiceList] = useState([]);
  const [docList, setDocList] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const serviceId = params.id;

  useEffect(() => {
    const promise = getRecords('/setup/v1/services/parent/all');
    const promise1 = getRecordById('/setup/v1/services/', serviceId);
    const promise2 = getRecords('/domain/data/doctypes/v1/all');

    promise
      .then((res) => {
        setParentServiceList(res.map((item) => ({ value: item.id, label: item.ServiceName })));
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      });

    promise1
      .then((res) => {
        form.setFieldsValue({
          serviceName: res.ServiceName,
          descriptions: res.Description,
          parentServiceId: res.ParentID,
        });

        setAlreadySelectedRows(res.docs.map((option) => option.id));
        //setParentServiceList(res.map((item) => ({ value: item.id, label: item.ServiceName })))
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      });

    promise2
      .then((res) => {
        setDocList(res);
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      });

    Promise.all([promise, promise1, promise2]);
  }, []);

  const onCancelForm = (event) => {
    event.preventDefault();
    navigate('/service/list');
  };

  const data = [];
  docList.forEach((record, recordIndex) => {
    data.push({
      key: recordIndex.toString(),
      docId: record.id,
      docName: record.DocName,
    });
  });

  const columns = [
    {
      title: 'Doc ID',
      dataIndex: 'docId',
    },
    {
      title: 'Document Name',
      dataIndex: 'docName',
    },
  ];

  const docSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setAlreadySelectedRows(selectedRows.map((item) => item.docId));

      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };

  const handleSubmit = (values) => {
    //const updateRequest = Object.assign({}, values);
    const updateRequest = {
      serviceName: values.serviceName,
      parentServiceId: values.parentServiceId,
      descriptions: values.descriptions,
      docId: alreadySelectedRows,
    };

    //console.log('updateRequest => ' + JSON.stringify(updateRequest));

    updateRecord(serviceId, updateRequest, '/setup/v1/services/')
      .then((response) => {
        if (response)
          notificationController.success({
            message: 'Service updated.',
          });
        navigate('/service/list');
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  };

  return (
    <BaseCol xs={24} sm={24} xl={24}>
      <BaseCard id="validation form" title="Edit Service" padding="1.25rem">
        <Form
          onFinish={handleSubmit}
          className="service-form"
          name="basic"
          form={form}
          labelCol={{ span: 6 }}
          //wrapperCol={{ span: 16 }}
          style={{ maxWidth: 500 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <FormItem
            name="serviceName"
            label="Service Name"
            rules={[
              {
                required: true,
                message: 'Service name is required',
              },
            ]}
          >
            <Input size="small" autoComplete="off" onKeyDown={(e) => handleEnter(e)} placeholder="Enter service name" />
          </FormItem>

          <FormItem label="Parent Service" name="parentServiceId">
            <Select showSearch size="small" placeholder="Please Select" onKeyDown={(e) => handleEnter(e)}>
              {parentServiceList.map((item, index) => (
                <Select.Option value={item.value} key={index}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem
            name="descriptions"
            label="Descriptions"
            rules={[
              {
                required: true,
                message: 'Descriptions is required',
              },
            ]}
          >
            <TextArea
              size="small"
              autoComplete="off"
              onKeyDown={(e) => handleEnter(e)}
              placeholder="Enter descriptions"
            />
          </FormItem>

          <FormItem label="Document Type" required={true}>
            <BaseTable
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: alreadySelectedRows,
                ...docSelection,
                // onChange:(keys)=>{
                //   setAlreadySelectedRows(keys)
                // }
              }}
              rowKey={(record) => record.docId}
              columns={columns}
              dataSource={data}
              pagination={{ pageSize: 5, onChange: (page) => this.handlePageChange(page) }}
              //loading={loading}
              bordered
              onKeyDown={(e) => handleEnter(e)}
            />
          </FormItem>

          <FormItem
            wrapperCol={{
              offset: 6,
              span: 9,
            }}
          >
            <BaseButtonsGroup onCancel={onCancelForm} />
          </FormItem>
        </Form>
      </BaseCard>
    </BaseCol>
  );
};
