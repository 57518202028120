import React, { Component, useState } from 'react';
import { saveRecord, searchRecord, getRecords } from '@app/utils/APIUtils';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  return (
    <CustomerPackageAdd
      {...props}
      navigation={navigate}
      isLoading={isLoading}
      setLoading={setLoading}
      current={current}
      setCurrent={setCurrent}
    />
  );
}

class CustomerPackageAdd extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(CustomerPackageAddForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
          current={this.props.current}
          setCurrent={this.props.setCurrent}
        />
      </div>
    );
  }
}

class CustomerPackageAddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      serviceId: {
        value: '',
      },
      customerName: {
        value: '',
      },
      mobileNumber: {
        value: '',
      },
      others: {
        value: '',
      },
      passportNo: {
        value: '',
      },
      nationalId: {
        value: '',
      },
      groupNumber: {
        value: '',
      },
      amount: {
        value: '',
      },
      packageFinalPrice: {
        value: '',
      },
      remarks: {
        value: '',
      },
      paymentReference: {
        value: '',
      },
      id: {
        value: '',
      },
      tags: {
        value: '',
      },
      serviceList: [],
      isReadonly: false,
      isRequired: false,
      recordSet: [],
      response: {},
      customerId: [],
      packageId: [],
      packageRecSet: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleServiceChange = this.handleServiceChange.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise;

    promise = getRecords('/setup/v1/services/all');

    this.setState({
      isLoading: true,
    });

    promise
      .then((response) => {
        this.setState({
          serviceList: response.map((item) => ({ value: item.id, label: item.ServiceName })),
        });
        console.log(this.state.serviceList);
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    Promise.all([promise]);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        //const saveRequest = Object.assign({}, values);
        //saveRequest.referenceStatusId  = 111;
        const saveRequest = {
          customerId: this.state.customerId,
          packageId: this.state.packageId,
          packageFinalPrice: values.packageFinalPrice,
        };
        console.log('saveRequest => ' + JSON.stringify(saveRequest));
        if (
          values.customerName === undefined &&
          values.mobileNumber === undefined &&
          values.groupNumber === undefined &&
          values.nationalId === undefined &&
          values.passportNo === undefined &&
          values.tags === undefined
        ) {
          notificationController.warning({
            message: 'Please enter one filtering criteria!',
          });
        } else {
          if (Object.keys(saveRequest.customerId).length === 0) {
            notificationController.warning({
              message: 'Please select any customer!',
            });
          } else if (Object.keys(saveRequest.packageId).length === 0) {
            notificationController.warning({
              message: 'Please select package!',
            });
          } else {
            saveRecord(saveRequest, '/customer/v1/packages').then((response) => {
              if (response.statusCode === 100) {
                notificationController.success({
                  message: 'Customer added to package.',
                });
                this.props.navigate('/m_customer_package/list');
              } else if (response.statusCode === 101) {
                notificationController.warning({
                  message: 'Customer already added to package!',
                });
              }
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
            });
          }
        }
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/m_customer_package/list');
  }

  handleSearch(event) {
    event.preventDefault();
    this.props.form.validateFields((_err, values) => {
      if (
        values.customerName === undefined &&
        values.mobileNumber === undefined &&
        values.groupNumber === undefined &&
        values.nationalId === undefined &&
        values.passportNo === undefined &&
        values.tags === undefined
      ) {
        notificationController.warning({
          message: 'Please enter one filtering criteria!',
        });
      }
      const searchRequest = {
        customerName: values.customerName === '' ? null : values.customerName,
        mobileNumber: values.mobileNumber === '' ? null : values.mobileNumber,
        groupNumber: values.groupNumber === '' ? null : values.groupNumber,
        nationalId: values.nationalId === '' ? null : values.nationalId,
        passportNo: values.passportNo === '' ? null : values.passportNo,
        tags: values.tags === '' ? null : values.tags,
      };
      console.log(values);
      //const searchRequest = Object.assign({}, values);
      console.log('searchRequest => ' + JSON.stringify(searchRequest));

      searchRecord(searchRequest, '/customer/v1/packages/search/customer')
        .then((response) => {
          //const recordSet = this.state.recordSet.slice();

          this.setState({
            recordSet: response,
          });
          setTimeout(() => {
            this.props.setLoading(false);
          }, 1000);
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
        });
    });
  }

  isFormInvalid() {
    return !(this.state.agentName.validateStatus === 'success');
  }

  clearForm(event) {
    event.preventDefault();
    this.props.form.resetFields();
  }

  handleServiceChange = (value) => {
    try {
      getRecords(`/setup/v1/packages/${value}/service`)
        .then((response) => {
          this.setState({
            packageRecSet: response,
          });
        })
        .catch((error) => {
          if (error)
            this.setState({
              packageRecSet: [],
            });
        });
    } catch (error) {}
  };

  render() {
    const { recordSet, packageRecSet } = this.state;
    const { getFieldDecorator } = this.props.form;

    const customerSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          customerId: selectedRows.map((item) => item.customerId),
        });

        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
    };

    const pkgSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          packageId: selectedRows.map((item) => item.packageId),
        });

        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
    };

    const data = [];
    const pkgData = [];

    //Customer record set
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        customerId: record.customerId,
        customerName: record.customerName,
        mobileNumber: record.mobileNumber,
        groupNumber: record.groupNumber,
      });
    });

    //Package record set
    packageRecSet.forEach((record, recordIndex) => {
      pkgData.push({
        key: recordIndex,
        packageId: record.id,
        packageName: record.PackageName,
        price: record.Price,
      });
    });

    const columns = [
      /* {
        title: 'ID',
        dataIndex: 'customerId',
        key: 'customerId',
      }, */
      {
        title: 'Name',
        dataIndex: 'customerName',
        key: 'customerName;',
      },
      {
        title: 'Mobile',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
      },
      {
        title: 'Group',
        dataIndex: 'groupNumber',
        key: 'groupNumber',
      },
    ];

    const pkgcolumns = [
      /*  {
        title: 'ID',
        dataIndex: 'packageId',
        key: 'packageId',
      }, */
      {
        title: 'Package Name',
        dataIndex: 'packageName',
        key: 'packageName;',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
      },
    ];

    return (
      <BaseCard id="validation form" title="Customer Package / Add" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <BaseCard id="mobileNumber" padding="1.25rem">
              <div style={{ marginBottom: 10 }}>
                <span style={{ color: '#01509A', fontWeight: 600 }}>Search Customer By (any one field)</span>
              </div>
              <Form
                onSubmit={this.handleSearch}
                className="search-form"
                name="basic"
                labelCol={{ span: 6 }}
                style={{ maxWidth: 500 }}
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <BaseRow gutter={[0, 0]}>
                  <BaseCol xs={24} sm={12} xl={12}>
                    <FormItem label="Mobile ">
                      {getFieldDecorator('mobileNumber', {
                        rules: [{ required: false, message: 'Mobile number is required!' }],
                      })(
                        <Input
                          name="mobileNumber"
                          placeholder="Enter mobile number"
                          value={this.state.mobileNumber.value}
                        />,
                      )}
                    </FormItem>

                    <FormItem label="Name">
                      {getFieldDecorator('customerName', {
                        rules: [{ required: false, message: 'Customer name is required!' }],
                      })(
                        <Input
                          name="customerName"
                          placeholder="Enter customer name"
                          value={this.state.customerName.value}
                        />,
                      )}
                    </FormItem>

                    <FormItem label="Group">
                      {getFieldDecorator('groupNumber', {
                        rules: [{ required: false, message: 'Group no is required!' }],
                      })(
                        <Input
                          name="groupNumber"
                          placeholder="Enter group number"
                          value={this.state.groupNumber.value}
                        />,
                      )}
                    </FormItem>
                  </BaseCol>
                  <BaseCol xs={24} sm={12} xl={12}>
                    <FormItem label="PP No">
                      {getFieldDecorator('passportNo', {
                        rules: [{ required: false, message: 'Passport no is required!' }],
                      })(
                        <Input
                          name="passportNo"
                          placeholder="Enter passport number"
                          value={this.state.passportNo.value}
                        />,
                      )}
                    </FormItem>

                    <FormItem label="NID ">
                      {getFieldDecorator('nationalId', {
                        rules: [{ required: false, message: 'NID is required!' }],
                      })(
                        <Input name="nationalId" placeholder="Enter national id" value={this.state.nationalId.value} />,
                      )}
                    </FormItem>

                    <FormItem label="Tags">
                      {getFieldDecorator('tags', {
                        rules: [{ required: false, message: 'Tags is required!' }],
                      })(<Input name="tags" placeholder="Enter tags" value={this.state.tags.value} />)}
                    </FormItem>
                  </BaseCol>
                  <BaseCol xs={24} sm={12} xl={12}>
                    <FormItem>
                      <BaseRow gutter={[10, 10]} wrap={false} style={{ justifyContent: 'space-between' }}>
                        <BaseCol span={12} xs={12} sm={12} xl={12}>
                          <BaseButton block type="primary" htmlType="submit" size="small">
                            Search
                          </BaseButton>
                        </BaseCol>
                        <BaseCol span={12} xs={12} sm={12} xl={12}>
                          <BaseButton block type="ghost" onClick={this.clearForm} size="small">
                            Reset
                          </BaseButton>
                        </BaseCol>
                      </BaseRow>
                    </FormItem>
                  </BaseCol>
                </BaseRow>
              </Form>

              {/*Table*/}
              <div className="page-Container" style={{ marginTop: 20 }}>
                <BaseTable
                  rowSelection={{
                    type: 'checkbox',
                    ...customerSelection,
                  }}
                  columns={columns}
                  dataSource={data}
                  pagination={{ pageSize: 5 }}
                  scroll={{ x: true }}
                  rowKey={(record) => record.customerId}
                  bordered
                />
              </div>
            </BaseCard>
          </BaseCol>
          <BaseCol xs={24} sm={12} xl={12}>
            <Form
              onSubmit={this.handleSubmit}
              className="customer-pkg"
              name="basic"
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="horizontal"
            >
              <FormItem label="Service">
                {getFieldDecorator('serviceId', {
                  rules: [{ required: true, message: 'Service is required!' }],
                })(
                  <Select
                    style={{ width: 250 }}
                    showSearch
                    placeholder="Select service"
                    size="small"
                    name="serviceId"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onChange={this.handleServiceChange}
                  >
                    {this.state.serviceList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem>
                <BaseCard id="customers" padding="1.25rem">
                  <div className="page-Container" style={{ marginTop: 20 }}>
                    <BaseTable
                      rowSelection={{
                        type: 'radio',
                        ...pkgSelection,
                      }}
                      columns={pkgcolumns}
                      dataSource={pkgData}
                      pagination={{ pageSize: 5 }}
                      scroll={{ x: true }}
                      rowKey={(record) => record.packageId}
                      bordered
                    />
                  </div>
                </BaseCard>
              </FormItem>

              <FormItem label="Package Final Price">
                {getFieldDecorator('packageFinalPrice', {
                  rules: [
                    { required: true, message: 'Final price is required!' },
                    {
                      pattern: /^\d*$/,
                      message: 'Only number allowed',
                    },
                  ],
                })(
                  <Input
                    style={{ width: 250 }}
                    addonAfter="BDT"
                    name="packageFinalPrice"
                    size="small"
                    placeholder="Enter package final price"
                    value={this.state.packageFinalPrice.value}
                  />
                )}
              </FormItem>
            </Form>
          </BaseCol>
        </BaseRow>
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            wrapperCol={{
              offset: 9,
              span: 6,
              style: { marginTop: 25 },
            }}
          >
            <BaseButtonsGroup onCancel={this.onCancelForm} />
          </FormItem>
        </Form>
      </BaseCard>
    );
  }
}
