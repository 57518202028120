import React, { Component, useState } from 'react';
import { deleteItemByID, getRecordById, getRecordSet, saveAsRecord } from '@app/utils/APIUtils';
import { Modal, Row, Descriptions } from 'antd';
import { PlusCircleOutlined, ImportOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';
import { Button } from '@app/components/common/BaseButton/BaseButton.styles';

export default function (props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  return (
    <FlightList
      {...props}
      loading={loading}
      setLoading={setLoading}
      page={page}
      setPage={setPage}
      navigate={navigate}
    />
  );
}

export class FlightList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      response: {},
      searchedText: '',
      visible: false,
      viewRecord: [],
      agentId: '',
      agentName: '',
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
    };
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
    this.handleSaveAs = this.handleSaveAs.bind(this);
    this.importFlightCsvFile = this.importFlightCsvFile.bind(this);
  }

  loadRecords(searchedText, page, size = LIST_SIZE) {
    this.setState({
      recordSet: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      isLoading: false,
      searchedText: '',
    });

    this.props.setLoading(true);

    let promise = getRecordSet(page, size, '/setup/v1/flights?page=', searchedText);

    if (!promise) {
      return;
    }

    promise
      .then((response) => {
        const recordSet = this.state.recordSet.slice();

        if (!(searchedText === '' || searchedText === undefined)) {
          this.setState({
            recordSet: recordSet.concat(response.content),
            page: response.page,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            last: response.last,
          });

          this.props.setLoading(false);
        } else {
          setTimeout(() => {
            this.setState({
              recordSet: recordSet.concat(response.content),
              page: response.page,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              last: response.last,
            });

            this.props.setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.props.setLoading(false);
      });

    console.log('Component mounted.');
  }

  componentDidMount() {
    this.loadRecords();
  }

  save() {
    this.props.navigate('/flight/new');
  }

  handleSaveAs(id, event) {
    event.preventDefault();

    saveAsRecord('/setup/v1/flights/', id)
      .then((response) => {
        if (response.statusCode === 200) {
          notificationController.success({
            message: 'Flight created.',
          });
          this.setState({
            visible: false,
          });
          this.loadRecords();
          this.props.navigate('/flight/list');
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  }

  edit(id) {
    this.props.navigate(`/flight/edit/${id}`);
  }

  importFlightCsvFile() {
    this.props.navigate('/flight/import/csv');
  }

  delete(id) {
    const { confirm } = Modal;
    return new Promise((reject, resolve) => {
      confirm({
        onCancel: () => {
          reject(true);
        },
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);

          //onRemoveFunctionality here
          deleteItemByID(id, '/setup/v1/flights/')
            .then((res) => {
              if (res.statusCode === 110) {
                notificationController.warning({
                  message: 'Flight - (' + id + ") mapper with customer you can't delete!",
                });
                this.props.navigate('/flight/list/');
              } else {
                this.setState({ recordSet: this.state.recordSet.filter((record) => record.flightId !== id) });
                notificationController.success({
                  message: 'Flight deleted',
                });
              }
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
            });
        },
      });
    });
  }

  view(id) {
    this.setState({
      visible: true,
    });

    let promise1 = getRecordById('/setup/v1/flights/', id);

    promise1.then((res) => {
      this.setState({
        viewRecord: res,
        agentId: res.AgentBean.id,
        agentName: res.AgentBean.AgentName,
      });
    });

    Promise.all([promise1]);
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { recordSet, viewRecord, agentId, agentName, totalElements } = this.state;

    //const vendorViews = [];
    const data = [];
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        flightId: record.flightId,
        flightNumber: record.flightNumber,
        flightDate: record.flightDate === null ? '' : record.flightDate + ' ' + record.flightTime,
        flightTime: record.flightTime,
        arrivalDate: record.arrivalDate === null ? '' : record.arrivalDate + ' ' + record.arrivalTime,
        arrivalTime: record.arrivalTime,
        leaderName: record.leaderName,
        agentName: record.agentName,
        depFromArrivalTo: record.depFrom + ' - ' + record.arrivalTo,
        flightType: record.flightType === 'R' ? 'Return' : 'Departure',
      });
    });

    const columns = [
      {
        title: 'Flight Number',
        dataIndex: 'flightNumber',
        key: 'flightNumber;',
        /* filteredValue: [this.state.searchedText],
        onFilter: (value, record) => {
          return (
            String(record.flightNumber).toLowerCase().includes(value.toLowerCase()) ||
            String(record.flightDate).toLowerCase().includes(value.toLowerCase()) ||
            String(record.flightTime).toLowerCase().includes(value.toLowerCase()) ||
            String(record.arrivalDate).toLowerCase().includes(value.toLowerCase()) ||
            String(record.arrivalTime).toLowerCase().includes(value.toLowerCase()) ||
            String(record.leaderName).toLowerCase().includes(value.toLowerCase()) ||
            String(record.groupName).toLowerCase().includes(value.toLowerCase()) ||
            String(record.agentName).toLowerCase().includes(value.toLowerCase()) ||
            String(record.isAssigned).toLowerCase().includes(value.toLowerCase())
          );
        }, */
      },
      {
        title: 'Flight Type',
        dataIndex: 'flightType',
        key: 'flightType',
      },
      {
        title: 'From & To',
        dataIndex: 'depFromArrivalTo',
        key: 'depFromArrivalTo',
      },
      {
        title: 'Flight Date & Time',
        dataIndex: 'flightDate',
        key: 'flightDate',
      },
      /* {
        title: 'Flight Time',
        dataIndex: 'flightTime',
        key: 'flightTime',
      }, */
      {
        title: 'Arrival Date & Time',
        dataIndex: 'arrivalDate',
        key: 'arrivalDate',
      },
      // {
      //     title: 'Return Time',
      //     dataIndex: 'returnFlightTime',
      //     key: 'returnFlightTime',
      // },
      // {
      //     title: 'Leader Name',
      //     dataIndex: 'leaderName',
      //     key: 'leaderName',
      // },
      {
        title: 'Agent Name',
        dataIndex: 'agentName',
        key: 'agentName',
      },
      {
        title: 'Action',
        key: 'action',
        width: '10%',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.flightId)}>
                {' '}
                View
              </BaseButton>

              <BaseButton severity="info" size="small" onClick={() => this.edit(record.flightId)}>
                {' '}
                Edit
              </BaseButton>

              <BaseButton type="primary" danger size="small" onClick={() => this.delete(record.flightId)}>
                {' '}
                Delete
              </BaseButton>
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <div className="page-Container">
        <S.Card
          id="basic-table"
          title="Flight List"
          padding="1.25rem 1.25rem 0"
          extra={
            <BaseButton
              type="info"
              className="btn btn-dark"
              onClick={this.save}
              size="small"
              icon={<PlusCircleOutlined />}
              style={{}}
            >
              Add
            </BaseButton>
          }
        >
          <div className="search-box" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ST.InputsWrapper>
              <SearchInput
                placeholder="Search..."
                onChange={(e) => {
                  this.setState(
                    {
                      searchedText: e.target.value,
                    },
                    () => {
                      console.log('New state in ASYNC callback:', this.state.searchedText);
                      this.loadRecords(this.state.searchedText);
                    },
                  );
                }}
                style={{
                  width: 230,
                  marginBottom: 10,
                }}
                allowClear
                size="small"
              />
            </ST.InputsWrapper>
            <div className="importBtn">
              <Button
                type="primary"
                className="btn btnImport"
                onClick={this.importFlightCsvFile}
                size="small"
                icon={<ImportOutlined />}
              >
                Import
              </Button>
            </div>
          </div>
          <BaseTable
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              total: totalElements,
              //showTotal: showTotal,
              showTotal: (showTotal) => (
                <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {showTotal} records</span>
              ),
              onChange: (page) => this.loadRecords(this.state.searchedText, page - 1),
            }}
            rowKey={(record) => record.key}
            loading={this.props.loading}
            bordered
            scroll={{ x: true }}
            rowClassName={(_record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          />
        </S.Card>
        <Modal
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={1000}
          style={{ top: 20 }}
          footer={[
            <Button
              key="saveAs"
              size="small"
              type="primary"
              style={{ display: 'inline' }}
              onClick={(e) => this.handleSaveAs(viewRecord.id, e)}
            >
              Save As
            </Button>,
          ]}
        >
          <Row>
            <Descriptions title="Flight Info" layout="vertical" bordered>
              <Descriptions.Item label="Flight Id">{viewRecord.id}</Descriptions.Item>
              <Descriptions.Item label="Airline Name">{viewRecord.FlightNumber}</Descriptions.Item>
              <Descriptions.Item label="Flight Date">{viewRecord.FlightDate}</Descriptions.Item>
              <Descriptions.Item label="Flight Time">{viewRecord.FlightTime}</Descriptions.Item>
              <Descriptions.Item label="Arrival Date">{viewRecord.ArrivalDate}</Descriptions.Item>
              <Descriptions.Item label="Arrival Time">{viewRecord.ArrivalTime}</Descriptions.Item>
              <Descriptions.Item label="Leader Name">{viewRecord.LeaderName}</Descriptions.Item>
              <Descriptions.Item label="Group Name">{viewRecord.GroupName}</Descriptions.Item>
              <Descriptions.Item label="Agent Id">{agentId}</Descriptions.Item>
              <Descriptions.Item label="Agent Name">{agentName}</Descriptions.Item>
              <Descriptions.Item label="Is Assigned">{viewRecord.IsAssigned === 1 ? 'Yes' : 'No'}</Descriptions.Item>
            </Descriptions>
          </Row>
        </Modal>
      </div>
    );
  }
}
