import React, { Component, useState } from 'react';
import { getRecordById, updateFormRecord, getRecords } from '@app/utils/APIUtils';
import { MAX_FILE_SIZE } from '@app/constants';
import { Descriptions, Input, notification, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;
const { TextArea } = Input;

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  return (
    <EditDocument {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} params={params} />
  );
}

class EditDocument extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(EditDocumentForm);
    return (
      <div>
        <AntWrappedOfficeForm
          dataId={this.props.params}
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class EditDocumentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      fileId: this.props.dataId.id,
      docList: [],
      attachment: null,
      previewAttachment: null,
      customerInfo: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.onFileChangeHandler = this.onFileChangeHandler.bind(this);
  }

  onFileChangeHandler = (event) => {
    const uploadedFile = event.target.files[0];

    const filetUrl = URL.createObjectURL(uploadedFile);

    this.setState({
      previewAttachment: filetUrl,
    });

    if (!uploadedFile) {
      return '';
    }

    this.setState({
      attachment: uploadedFile,
    });

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      event.target.value = null;

      notification.warning({
        message: 'File Size',
        description: 'File size is greater than maximum limit (2MB)',
      });
    }
  };

  componentDidMount() {
    let promise;

    promise = getRecords('/domain/data/doctypes/v1/all');

    this.setState({
      isLoading: true,
    });

    promise
      .then((response) => {
        this.setState({
          docList: response.map((item) => ({ value: item.id, label: item.DocName })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.setState({
          isLoading: false,
        });
      });

    let promise1 = getRecordById('/customer/v1/documents/', this.state.fileId);
    promise1.then((res) => {
      this.props.form.setFieldsValue({
        customerId: res.CustomerBean.id,
        fileName: res.FileName,
        descriptions: res.FileDesc === 'null' ? '' : res.FileDesc,
        documentId: res.DocBean.id,
      });

      this.setState({
        attachment: res.File,
        customerInfo: res.CustomerBean,
        documentId: res.DocBean.id,
      });

      console.log('File=>' + this.state.attachment);
    });
    console.log(promise1);
    Promise.all([promise, promise1]);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //const updateRequest = Object.assign({}, values);
        //updateRequest.referenceStatusId  = 111;
        //console.log('updateRequest => ' + JSON.stringify(updateRequest));

        let formData = new FormData();

        formData.append('customerId', values.customerId);
        formData.append('fileName', values.fileName);
        if (values.descriptions !== undefined) {
          formData.append('descriptions', values.descriptions);
        }
        formData.append('documentId', this.state.documentId);
        formData.append('attachment', this.state.attachment);

        console.log('data', formData);
        console.log('file', this.state.attachment);

        updateFormRecord(this.state.fileId, formData, '/customer/v1/documents/')
          .then((response) => {
            if (response.statusCode === 101) {
              notificationController.warning({
                message: 'Doc type (' + values.docId + ') already exist!',
              });
            } else {
              notificationController.success({
                message: 'Document updated.',
              });

              this.props.navigate('/customer/doc/list');
            }
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/customer/doc/list');
  }

  render() {
    console.log(this.state.customerInfo);
    const { getFieldDecorator } = this.props.form;
    return (
      <BaseCard id="validation form" title="Edit Customer Document" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              onSubmit={this.handleSubmit}
              className="doc-form"
              name="basic"
              labelCol={{ span: 6 }}
              //wrapperCol={{ span: 16 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem label="Customer ID">
                {getFieldDecorator('customerId', {
                  rules: [{ required: true, message: 'Customer id is required!' }],
                })(<Input name="customerId" size="small" disabled={true} />)}
              </FormItem>

              <FormItem label="Document Type">
                {getFieldDecorator('documentId', {
                  rules: [{ required: true, message: 'Please select doc type!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select"
                    size="small"
                    name="documentId"
                    onKeyDown={(e) => handleEnter(e)}
                    disabled={true}
                  >
                    {this.state.docList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="File Name">
                {getFieldDecorator('fileName', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input file name!',
                    } /* ,
                    {
                      min: 4,
                      message: 'File name should be minimum 4 character',
                    }, */,
                  ],
                })(
                  <Input
                    name="fileName"
                    size="small"
                    autoComplete="off"
                    onKeyDown={(e) => handleEnter(e)}
                    placeholder="Enter file name"
                  />,
                )}
              </FormItem>

              <FormItem label="Descriptions">
                {getFieldDecorator('descriptions', {
                  rules: [{ required: false, message: 'Please input descriptions!' }],
                })(
                  <TextArea
                    name="descriptions"
                    size="small"
                    autoComplete="off"
                    onKeyDown={(e) => handleEnter(e)}
                    placeholder="Enter descriptions"
                  />,
                )}
              </FormItem>

              <FormItem label="Attachment">
                {getFieldDecorator('attachment', {
                  rules: [{ required: false, message: 'Please Choose a attachment!' }],
                })(
                  <Input
                    type="file"
                    name="attachment"
                    size="small"
                    accept="application/pdf"
                    onKeyDown={(e) => handleEnter(e)}
                    onChange={this.onFileChangeHandler}
                  />,
                )}
                <div style={{ marginTop: 10 }}>
                  {this.state.previewAttachment !== null ? (
                    <object
                      style={{ width: '100%', height: '100pt' }}
                      type="application/pdf"
                      data={this.state.previewAttachment}
                    ></object>
                  ) : (
                    <object
                      style={{ width: '100%', height: '100pt' }}
                      type="application/pdf"
                      data={'data:application/pdf;base64,' + this.state.attachment}
                    ></object>
                  )}
                </div>
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 6,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} />
              </FormItem>
            </Form>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <Descriptions title="Customer Info" bordered size="middle">
              <Descriptions.Item label="Customer Name" span={3}>
                {this.state.customerInfo.CustName}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {this.state.customerInfo.Email}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile No" span={3}>
                {this.state.customerInfo.MobileNo}
              </Descriptions.Item>
            </Descriptions>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}
