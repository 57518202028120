import React, { useEffect, useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet, useLocation } from 'react-router-dom';
import { MEDICAL_DASHBOARD_PATH, BASE_PATH } from '@app/components/router/AppRouter';
import { useResponsive } from '@app/hooks/useResponsive';
import { References } from '@app/components/common/References/References';
import { notificationController } from '@app/controllers/notificationController';
import { getCurrentUser, getUserProfile } from '@app/utils/APIUtils';

interface DataPathProps {
  datapath: string;
  agentName: string | undefined;
}

const MainLayout: React.FC <DataPathProps>= ({datapath}) => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const location = useLocation();
  const [data, setData] = useState<DataPathProps>();

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    getCurrentUser().then((res) => {
      setData(res)
      getUserProfile('/sa/users/', res.username).then((response) =>{
        setData(response)
      }).catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error,
        });
      });
    }).catch((error) => {
      notificationController.error({
        message: 'Failed! ' + error,
      });
    });
  }, []);
  
  useEffect(() => {
    setIsTwoColumnsLayout([MEDICAL_DASHBOARD_PATH, BASE_PATH].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);

  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} datapath={datapath}/>
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header 
            toggleSider={toggleSider} 
            isSiderOpened={!siderCollapsed} 
            isTwoColumnsLayout={isTwoColumnsLayout} 
            agentName={data?.agentName?.toUpperCase()}
          />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
          </div>
          {!isTwoColumnsLayout && <References />}
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
