import React, { Component, useState } from 'react';
import { /* deleteItemByID, */ getRecordById, getRecordSet } from '@app/utils/APIUtils';
import '@ant-design/compatible/assets/index.css';
import moment from 'moment';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import { LIST_SIZE } from '@app/constants';
import { Badge, Descriptions, Modal, Row, Tag } from 'antd';

export default function (props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  return (
    <RegistrationList
      {...props}
      loading={loading}
      setLoading={setLoading}
      page={page}
      setPage={setPage}
      navigate={navigate}
    />
  );
}

class RegistrationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      searchedText: '',
      visible: false,
      viewRecord: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
    };
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    //this.delete = this.delete.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
  }

  loadRecords(searchedText = '', page, size = LIST_SIZE) {
    this.setState({
      recordSet: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      isLoading: false,
    });

    this.props.setLoading(true);

    let promise = getRecordSet(page, size, '/public/customer/v1/registered?page=', searchedText);

    if (!promise) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    promise
      .then((response) => {
        const recordSet = this.state.recordSet.slice();

        if (!(searchedText === '')) {
          this.setState({
            recordSet: recordSet.concat(response.content),
            page: response.page,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            last: response.last,
          });

          this.props.setLoading(false);
        } else {
          setTimeout(() => {
            this.setState({
              recordSet: recordSet.concat(response.content),
              page: response.page,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              last: response.last,
            });

            this.props.setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.props.setLoading(false);
      });
    console.log('Component mounted.');
  }

  componentDidMount() {
    this.loadRecords();
  }

  save() {
    this.props.navigate('/registration/new');
  }

  edit(id) {
    this.props.navigate(`/registration/edit/${id}`);
  }

  //? Hide delete option
  /* delete(id) {
    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);

          //onRemoveFunctionality here
          deleteItemByID(id, '/public/customer/v1/registered/').then((res) => {
            if (res) this.setState({ recordSet: this.state.recordSet.filter((record) => record.regId !== id) });
            notificationController.success({
              message: 'Registration deleted successfully.',
            });
          });
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  } */

  view(id) {
    this.setState({
      visible: true,
    });

    let promise1 = getRecordById('/public/customer/v1/registered/', id);
    promise1.then((res) => {
      this.setState({
        viewRecord: res,
      });
    }, []);

    Promise.all([promise1]);
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { recordSet, totalElements, viewRecord } = this.state;

    //const vendorViews = [];
    const data = [];
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        registrationId: record.regId,
        hajYearId: record.hajYearId,
        hajSerialNo: record.hajSerialNo,
        customerName: record.custName,
        mobileNumber: record.mobileNo,
        groupNumber: record.groupNumber === null ? '' : record.groupNumber,
        passportNo: record.passportNo,
        registrationDate: moment(record.regDate).format('YYYY-MM-DD'),
        regVoucherNo: record.regVoucherNo,
        pilgrimId: record.pid,
      });
    });

    const columns = [
      {
        title: 'Group No',
        dataIndex: 'groupNumber',
        key: 'groupNumber;',
        render: (_, { groupNumber }) => {
          let color = groupNumber.length >= 1 ? '#108ee9' : '';
          return (
            groupNumber && (
              <React.Fragment>
                <Tag color={color} key={groupNumber}>
                  {groupNumber}
                </Tag>
              </React.Fragment>
            )
          );
        },
      },
      {
        title: 'Haj Year',
        dataIndex: 'hajYearId',
        key: 'hajYearId;',
      },
      {
        title: 'Haj Serial No',
        dataIndex: 'hajSerialNo',
        key: 'hajSerialNo;',
      },
      {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName;',
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
      },
      {
        title: 'Reg. Date',
        dataIndex: 'registrationDate',
        key: 'registrationDate',
      },
      {
        title: 'Reg Voucher No',
        dataIndex: 'regVoucherNo',
        key: 'regVoucherNo;',
      },
      {
        title: 'Pilgrim ID',
        dataIndex: 'pilgrimId',
        key: 'pilgrimId;',
      },
      {
        title: 'Action',
        key: 'action',
        width: '12%',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.registrationId)}>
                View
              </BaseButton>

              <BaseButton severity="info" size="small" onClick={() => this.edit(record.registrationId)}>
                Edit
              </BaseButton>

              {/* <BaseButton type="primary" danger size="small" onClick={() => this.delete(record.registrationId)}>
                {' '}
                Delete
              </BaseButton> */}
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <div className="page-Container">
        <S.Card
          id="basic-table"
          title="Registration List"
          padding="1.25rem 1.25rem 0"
          extra={
            <BaseButton
              type="info"
              className="btn btn-dark"
              onClick={this.save}
              size="small"
              icon={<PlusCircleOutlined />}
              style={{}}
            >
              Add
            </BaseButton>
          }
        >
          <div className="search-box">
            <ST.InputsWrapper>
              <SearchInput
                placeholder="Search..."
                onChange={(e) => {
                  this.setState(
                    {
                      searchedText: e.target.value,
                    },
                    () => {
                      console.log('New state in ASYNC callback:', this.state.searchedText);
                      this.loadRecords(this.state.searchedText);
                    },
                  );
                }}
                style={{
                  width: 230,
                  marginBottom: 10,
                }}
                allowClear
                size="small"
              />
            </ST.InputsWrapper>
          </div>
          <BaseTable
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              total: totalElements,
              showTotal: (showTotal) => (
                <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {showTotal} records</span>
              ),
              onChange: (page) => this.loadRecords(page - 1),
            }}
            loading={this.props.loading}
            bordered
            scroll={{ x: true }}
            rowClassName={(_record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          />
        </S.Card>
        <Modal
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={1000}
          style={{ top: 20 }}
          footer={null}
        >
          <Row>
            <Descriptions title="Registration Info" layout="horizontal" bordered size="middle">
              <Descriptions.Item label="REGID">{viewRecord.regId}</Descriptions.Item>
              <Descriptions.Item label="Customer Name">{viewRecord.customerName}</Descriptions.Item>
              <Descriptions.Item label="Mobile Number">{viewRecord.mobileNumber}</Descriptions.Item>
              <Descriptions.Item label="Email">{viewRecord.email}</Descriptions.Item>
              <Descriptions.Item label="Haj Year">{viewRecord.hajYear}</Descriptions.Item>
              <Descriptions.Item label="Haj Serial">{viewRecord.hajSerial}</Descriptions.Item>
              <Descriptions.Item label="Reg Date">
                {moment(viewRecord.registrationDate).format('YYYY-MM-DD')}
              </Descriptions.Item>
              <Descriptions.Item label="Reg Boucher No">{viewRecord.regVoucherNo}</Descriptions.Item>
              <Descriptions.Item label="Pilgrim ID">{viewRecord.pilgrimId}</Descriptions.Item>
              <Descriptions.Item label="Biometrics ID">{viewRecord.biometricsId}</Descriptions.Item>
              <Descriptions.Item label="E-Haj Tag">{viewRecord.ehajTag}</Descriptions.Item>
              <Descriptions.Item label="Visa No">{viewRecord.visaNo}</Descriptions.Item>
              <Descriptions.Item label="Status">{viewRecord.recordStat === 3 ? 'Registered' : ''}</Descriptions.Item>
              <Descriptions.Item label="Created Date">
                {moment(viewRecord.createdAt).format('YYYY-MM-DD')}
              </Descriptions.Item>
              <Descriptions.Item label="Updated Date">
                {moment(viewRecord.updatedAt).format('YYYY-MM-DD')}
              </Descriptions.Item>
              <Descriptions.Item label="Created By">{viewRecord.createdBy}</Descriptions.Item>
              <Descriptions.Item label="Created At">
                {moment(viewRecord.createdDate).format('YYYY-MM-DD')}
              </Descriptions.Item>
              <Descriptions.Item label="Updated By">{viewRecord.updatedBy}</Descriptions.Item>
              <Descriptions.Item label="Updated At">
                {moment(viewRecord.updatedDate).format('YYYY-MM-DD')}
              </Descriptions.Item>
              <Descriptions.Item label="Activated">
                <Badge
                  status={viewRecord.isActive === 1 ? 'success' : 'error'}
                  text={viewRecord.isActive === 1 ? 'Yes' : 'No'}
                />
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Modal>
      </div>
    );
  }
}
