import React, { Component, useState } from 'react';
import { deleteItemByID, getRecordById, getRecordSet } from '@app/utils/APIUtils';
import { Modal, Row, Descriptions, Button } from 'antd';
import { PlusCircleOutlined, ImportOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';

export default function (props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  return (
    <BusList {...props} loading={loading} setLoading={setLoading} page={page} setPage={setPage} navigate={navigate} />
  );
}

export class BusList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      response: {},
      searchedText: '',
      open: false,
      parentServiceName: '',
      viewRecord: [],
      agentId: '',
      agentName: '',
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
    };
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.importCsvFile = this.importCsvFile.bind(this);
    this.delete = this.delete.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
  }

  loadRecords(searchedText, page, size = LIST_SIZE) {
    this.setState({
      recordSet: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      isLoading: false,
    });

    this.props.setLoading(true);

    let promise = getRecordSet(page, size, '/setup/v1/buses?page=', searchedText);

    if (!promise) {
      return;
    }

    promise
      .then((response) => {
        const recordSet = this.state.recordSet.slice();

        if (!(searchedText === '' || searchedText === undefined)) {
          this.setState({
            recordSet: recordSet.concat(response.content),
            page: response.page,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            last: response.last,
          });

          this.props.setLoading(false);
        } else {
          setTimeout(() => {
            this.setState({
              recordSet: recordSet.concat(response.content),
              page: response.page,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              last: response.last,
            });

            this.props.setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) this.props.setLoading(false);
      });

    console.log('Component mounted.');
  }

  componentDidMount() {
    this.loadRecords();
  }

  save() {
    this.props.navigate('/bus/new');
  }

  importCsvFile() {
    this.props.navigate('/bus/import/csv');
  }

  edit(id) {
    this.props.navigate(`/bus/edit/${id}`);
  }

  delete(id) {
    const { confirm } = Modal;
    return new Promise((reject, resolve) => {
      confirm({
        onCancel: () => {
          reject(true);
        },
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);

          //onRemoveFunctionality here
          deleteItemByID(id, '/setup/v1/buses/')
            .then((res) => {
              if (res.statusCode === 110) {
                notificationController.error({
                  message: 'Bus already mapper',
                });
                this.props.navigate('/bus/list/');
              } else if (res.statusCode === 200) {
                this.setState({ recordSet: this.state.recordSet.filter((record) => record.busId != id) });
                notificationController.success({
                  message: 'Bus deleted',
                });
              }
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
            });
        },
      });
    });
  }

  view(id) {
    this.setState({
      open: true,
    });

    let promise1 = getRecordById('/setup/v1/buses/', id);

    promise1.then((res) => {
      this.setState({
        viewRecord: res,
        agentId: res.AgentBean.id,
        agentName: res.AgentBean.AgentName,
      });
    });

    Promise.all([promise1]);
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      open: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      open: false,
    });
  };

  render() {
    const { recordSet, totalElements, viewRecord, agentId, agentName } = this.state;

    //const vendorViews = [];
    const data = [];
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        busId: record.busId,
        busNo: record.busNo,
        busLabel: record.busLabel,
        busRoute: record.busRoute,
        seats: record.seats,
        contactInfo: record.contactInfo,
        agentName: record.agentName,
        movementType: record.movementType,
      });
    });

    const columns = [
      {
        title: 'Movement',
        dataIndex: 'movementType',
        key: 'movementType',
        width: '20%',
      },
      {
        title: 'Bus No',
        dataIndex: 'busNo',
        key: 'busNo;',
        width: '10%',
        /* filteredValue: [this.state.searchedText],
        onFilter: (value, record) => {
          return (
            String(record.busNo).toLowerCase().includes(value.toLowerCase()) ||
            String(record.busLabel).toLowerCase().includes(value.toLowerCase()) ||
            String(record.seats).toLowerCase().includes(value.toLowerCase()) ||
            String(record.contactInfo).toLowerCase().includes(value.toLowerCase()) ||
            String(record.agentName).toLowerCase().includes(value.toLowerCase()) ||
            String(record.isAssigned).toLowerCase().includes(value.toLowerCase())
          );
        }, */
      },
      {
        title: 'Bus Route',
        dataIndex: 'busRoute',
        key: 'busRoute',
        width: '20%',
      },
      {
        title: 'Bus Label',
        dataIndex: 'busLabel',
        key: 'busLabel',
        width: '20%',
      },
      {
        title: 'Total Seats',
        dataIndex: 'seats',
        key: 'seats',
        width: '10%',
      },
      {
        title: 'Agent Name',
        dataIndex: 'agentName',
        key: 'agentName',
        width: '25%',
      },
      /* {
        title: 'Contact',
        dataIndex: 'contactInfo',
        key: 'contactInfo',
      }, */
      {
        title: 'Action',
        key: 'action',
        width: '15%',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.busId)}>
                View
              </BaseButton>

              <BaseButton severity="info" size="small" onClick={() => this.edit(record.busId)}>
                Edit
              </BaseButton>

              <BaseButton type="primary" danger size="small" onClick={() => this.delete(record.busId)}>
                Delete
              </BaseButton>
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <div className="page-Container">
        <S.Card
          id="basic-table"
          title="Bus List"
          padding="1.25rem 1.25rem 0"
          extra={
            <BaseButton
              type="info"
              className="btn btn-dark"
              onClick={this.save}
              size="small"
              icon={<PlusCircleOutlined />}
              style={{}}
            >
              Add
            </BaseButton>
          }
        >
          <div className="search-box" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ST.InputsWrapper>
              <SearchInput
                placeholder="Search..."
                onChange={(e) => {
                  this.setState(
                    {
                      searchedText: e.target.value,
                    },
                    () => {
                      console.log('New state in ASYNC callback:', this.state.searchedText);
                      this.loadRecords(this.state.searchedText);
                    },
                  );
                }}
                style={{
                  width: 230,
                  marginBottom: 10,
                }}
                allowClear
                size="small"
              />
            </ST.InputsWrapper>
            <div className="importBtn">
              <Button
                type="primary"
                className="btn btnImport"
                onClick={this.importCsvFile}
                size="small"
                icon={<ImportOutlined />}
              >
                Import
              </Button>
            </div>
          </div>

          <BaseTable
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              total: totalElements,
              showTotal: (showTotal) => (
                <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {showTotal} records</span>
              ),
              onChange: (page) => this.loadRecords(this.state.searchedText, page - 1),
            }}
            rowKey={(record) => record.key}
            loading={this.props.loading}
            bordered
            scroll={{ x: true }}
            rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          />
        </S.Card>

        <Modal
          open={this.state.open}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={1000}
          style={{ top: 20 }}
        >
          <Row>
            <Descriptions title="Bus Info" layout="vertical" bordered>
              <Descriptions.Item label="Bus Id">{viewRecord.id}</Descriptions.Item>
              <Descriptions.Item label="Bus No">{viewRecord.BusNo}</Descriptions.Item>
              <Descriptions.Item label="Bus Label">{viewRecord.BusLabel}</Descriptions.Item>
              <Descriptions.Item label="Total Seats">{viewRecord.TotalSeats}</Descriptions.Item>
              <Descriptions.Item label="Contact Info" span={3}>
                {viewRecord.ContactInfo}
              </Descriptions.Item>
              <Descriptions.Item label="Agent Id">{agentId}</Descriptions.Item>
              <Descriptions.Item label="Agent Name">{agentName}</Descriptions.Item>
            </Descriptions>
          </Row>
        </Modal>
      </div>
    );
  }
}
