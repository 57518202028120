import React from 'react';
//import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
//import { getDifference } from 'utils/utils';
//import * as S from './StatisticsInfo.styles';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import * as SS from '@app/components/medical-dashboard/statisticsCards/statisticsCard/StatisticsProgress/StatisticsProgress.styles';
interface StatisticsInfoProps {
  name: string;
  value: number;
  prevValue: number;
}

export const StatisticsInfo: React.FC<StatisticsInfoProps> = ({ /* name, */ prevValue }) => {
  return (
    <BaseSpace direction="vertical" size={20}>
      {/* <S.Title>{name}</S.Title> */}

      <SS.ValueText>{prevValue}</SS.ValueText>

      {/* {prevValue && (
        <S.Text>
          <S.IconWrapper> {prevValue > value ? <CaretUpOutlined /> : <CaretDownOutlined />}</S.IconWrapper>
          This Year: {prevValue}
        </S.Text>
      )} */}
    </BaseSpace>
  );
};
