import React, { Component, useState } from 'react';
import { saveFormRecord, getRecords } from '@app/utils/APIUtils';
import {
  ACTIVITY_NAME_MIN_LENGTH,
  ACTIVITY_NAME_MAX_LENGTH,
  USERNAME_MIN_LENGTH,
  USERNAME_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  MAX_FILE_SIZE,
} from '@app/constants';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;
const { TextArea } = Input;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  return <AddActivity {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} />;
}

class AddActivity extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(AddActivityForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class AddActivityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityId: {
        value: '',
      },
      activityName: {
        value: '',
      },
      descriptions: {
        value: '',
      },
      prerequisites: {
        value: '',
      },
      file: null,
      notes: {
        value: '',
      },
      parentActivityId: {
        value: '',
      },
      parentActivityList: [],
      previewImage: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.onFileChangeHandler = this.onFileChangeHandler.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  onFileChangeHandler = (event) => {
    const uploadedFile = event.target.files[0];

    const filetUrl = URL.createObjectURL(uploadedFile);

    this.setState({
      previewImage: filetUrl,
    });

    if (!uploadedFile) {
      return '';
    }

    this.setState({
      file: uploadedFile,
    });

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      event.target.value = null;

      notification.warning({
        message: 'File Size',
        description: 'File size is greater than maximum limit (2MB)',
      });
    }
  };

  componentDidMount() {
    this.props.setLoading(true);

    let promise;

    promise = getRecords('/service/v1/activities/all');

    promise
      .then((response) => {
        this.setState({
          parentActivityList: response.map((item) => ({ value: item.id, label: item.ActivityName })),
        });
        console.log(this.state.parentActivityList);
      })
      .catch((error) => {
        if (error) this.props.setLoading(false);
      });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        //const saveRequest = Object.assign({}, values);
        //saveRequest.referenceStatusId  = 111;

        let formData = new FormData();

        formData.append('activityName', values.activityName);
        formData.append('parentActivityId', values.parentActivityId === undefined ? '' : values.parentActivityId);
        formData.append('descriptions', values.descriptions);
        formData.append('prerequisites', values.prerequisites === undefined ? '' : values.prerequisites);
        formData.append('notes', values.notes === undefined ? '' : values.notes);

        formData.append('file', this.state.file);

        console.log('data', formData);
        console.log('file', this.state.file);

        saveFormRecord(formData, '/service/v1/activities')
          .then((response) => {
            if (response)
              notificationController.success({
                message: 'Activity created',
              });
            this.props.navigate('/activity/list');
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/activity/list');
  }

  isFormInvalid() {
    return !(this.state.activityName.validateStatus === 'success' && this.state.notes.validateStatus === 'success');
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Activity Add" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="activity-form"
            name="basic"
            labelCol={{ span: 6 }}
            //wrapperCol={{ span: 16 }}
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem label="Activity Name">
              {getFieldDecorator('activityName', {
                rules: [
                  {
                    required: true,
                    message: 'Activity name required!',
                  },
                  {
                    min: 4,
                    message: 'Activity name should be minimum 4 character',
                  },
                ],
              })(
                <Input
                  name="activityName"
                  size="small"
                  autoComplete="off"
                  placeholder="Enter activity name"
                  value={this.state.activityName.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Parent Activity">
              {getFieldDecorator('parentActivityId', {
                rules: [{ required: false, message: 'Parent activity required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select"
                  size="small"
                  name="parentActivityId"
                  value={this.state.parentActivityId.value}
                  onKeyDown={(e) => handleEnter(e)}
                  optionFilterProp="children"
                  onSearch={onSearch}
                >
                  {this.state.parentActivityList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Descriptions">
              {getFieldDecorator('descriptions', {
                rules: [{ required: true, message: 'Descriptions required!' }],
              })(
                <TextArea
                  name="descriptions"
                  autoComplete="off"
                  size="small"
                  placeholder="Enter descriptions"
                  value={this.state.descriptions.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Prerequisites">
              {getFieldDecorator('prerequisites', {
                rules: [{ required: false, message: 'Please input prerequisites!' }],
              })(
                <Input
                  name="prerequisites"
                  autoComplete="off"
                  size="small"
                  placeholder="Enter prerequisites"
                  value={this.state.prerequisites.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Attachment">
              {getFieldDecorator('file', {
                rules: [{ required: false, message: 'Please Choose a file!' }],
              })(
                <Input
                  type="file"
                  name="file"
                  size="small"
                  onChange={this.onFileChangeHandler}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}

              {this.state.previewImage ? (
                <div>
                  <img
                    src={this.state.previewImage}
                    alt="#"
                    style={{
                      height: 120,
                      width: 120,
                      marginTop: 10,
                    }}
                  />
                </div>
              ) : null}
            </FormItem>

            <FormItem label="Notes">
              {getFieldDecorator('notes', {
                rules: [{ required: false, message: 'Please input notes!' }],
              })(
                <Input
                  name="notes"
                  autoComplete="off"
                  size="small"
                  placeholder="Enter notes"
                  value={this.state.notes.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem
              wrapperCol={{
                offset: 6,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }

  // Validation Functions

  validateActivityName = (inputValue) => {
    if (inputValue.length < ACTIVITY_NAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Activity Name is too short (Minimum ${ACTIVITY_NAME_MIN_LENGTH} characters needed.)`,
      };
    } else if (inputValue.length > ACTIVITY_NAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `Activity Name is too long (Maximum ${ACTIVITY_NAME_MAX_LENGTH} characters allowed.)`,
      };
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
  };

  validateDescriptions = (descriptions) => {
    if (!descriptions) {
      return {
        validateStatus: 'error',
        errorMsg: 'Descriptions may not be empty',
      };
    }

    if (descriptions.length > DESCRIPTION_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Descriptions is too long (Maximum ${DESCRIPTION_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validatePrerequisites = (prerequisites) => {
    if (prerequisites.length < USERNAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `prerequisites is too short (Minimum ${USERNAME_MIN_LENGTH} characters needed.)`,
      };
    } else if (prerequisites.length > USERNAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `prerequisites is too long (Maximum ${USERNAME_MAX_LENGTH} characters allowed.)`,
      };
    } else {
      return {
        validateStatus: null,
        errorMsg: null,
      };
    }
  };

  validateNotes = (notes) => {
    if (notes.length < USERNAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Notes is too short (Minimum ${USERNAME_MIN_LENGTH} characters needed.)`,
      };
    } else if (notes.length > USERNAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `Notes is too long (Maximum ${USERNAME_MAX_LENGTH} characters allowed.)`,
      };
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
  };
}
