import React, { useEffect, useMemo, useState } from 'react';
import { useResponsive } from 'hooks/useResponsive';
//import { StatisticsCard } from '@app/components/medical-dashboard/statisticsCards/statisticsCard/StatisticsCard/StatisticsCard';
//import { /* getStatistics, */ Statistic } from 'api/statistics.api';
import { payment as configStatistics } from 'constants/config/payment';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { getRecords } from '@app/utils/APIUtils';
import { PaymentStatCard } from './PaymentStatCard';
import { PaymentInfo } from './PaymentInfo.api';

export const PaymentStatCards: React.FC = () => {
  const [statistics, setStatistics] = useState<PaymentInfo[]>([]);

  const { isTablet } = useResponsive();

  useEffect(() => {
    const promise = getRecords('/admin/v1/dashboard/payments/details');
    promise.then((res) => setStatistics(res));
  }, []);

  const statisticsCards = useMemo(
    () =>
      statistics.map((st, index) => {
        const currentStatistic = configStatistics.find((el) => el.id === st.id);

        return currentStatistic ? (
          <BaseCol
            key={st.id}
            id={currentStatistic.name}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            order={(isTablet && index + 1) || 0}
          >
            <PaymentStatCard
              id={currentStatistic.cardId}
              name={currentStatistic.title}
              total={st.total}
              byToday={st.byToday}
              thisMonth={st.thisMonth}
              thisYear={st.thisYear}
            />
          </BaseCol>
        ) : null;
      }),
    [statistics, isTablet],
  );

  return <>{statisticsCards}</>;
};
