import React, { Component, useState } from 'react';
import { deleteItemByID, getRecordSet } from '@app/utils/APIUtils';
import {
  Modal,
  // Row,
  // Descriptions,
  // Badge
} from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';
import { PlusCircleOutlined } from '@ant-design/icons';

export default function (props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  return (
    <RoomList {...props} loading={loading} setLoading={setLoading} page={page} setPage={setPage} navigate={navigate} />
  );
}

class RoomList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      response: {},
      searchedText: '',
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
    };
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
  }

  loadRecords(searchedText = '', page, size = LIST_SIZE) {
    this.setState({
      recordSet: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      isLoading: false,
    });

    this.props.setLoading(true);

    let promise = getRecordSet(page, size, '/setup/v1/rooms?page=', searchedText);

    if (!promise) {
      return;
    }

    promise
      .then((response) => {
        const recordSet = this.state.recordSet.slice();

        if (!(searchedText === '')) {
          this.setState({
            recordSet: recordSet.concat(response.content),
            page: response.page,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            last: response.last,
          });

          this.props.setLoading(false);
        } else {
          setTimeout(() => {
            this.setState({
              recordSet: recordSet.concat(response.content),
              page: response.page,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              last: response.last,
            });

            this.props.setLoading(false);
          }, 500);
        }
      })
      .catch((error) => {
        notificationController.error({
          message: error.message,
        });
        this.props.setLoading(false);
      });
    console.log('Component mounted.');
  }

  componentDidMount() {
    this.loadRecords();
  }

  save() {
    this.props.navigate('/room/new');
  }

  edit(id) {
    this.props.navigate(`/room/edit/${id}`);
  }

  delete(id) {
    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);

          //onRemoveFunctionality here
          deleteItemByID(id, '/setup/v1/rooms/').then((res) => {
            if (res.statusCode === 110) {
              notificationController.warning({
                message: 'Room - (' + id + ") mapper with customer you can't delete!",
              });
              this.props.navigate('/room/list/');
            } else {
              this.setState({ recordSet: this.state.recordSet.filter((record) => record.roomId !== id) });
              this.loadRecords();
              notificationController.success({
                message: 'Room deleted',
              });
              this.props.navigate('/room/list/');
            }
          });
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  }

  view(id) {
    this.props.navigate(`/activity/edit/${id}`);
  }

  render() {
    const { recordSet, totalElements } = this.state;

    //const vendorViews = [];
    const data = [];
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        roomId: record.roomId,
        hotelId: record.hotelId,
        roomType: record.roomType,
        roomNo: record.roomNo,
        totalBeds: record.totalBeds,
        hotelName: record.hotelName,
        isAssigned: record.isAssigned === 1 ? 'Yes' : 'No',
        remarks: record.remarks,
      });
    });

    const columns = [
      {
        title: 'Room ID',
        dataIndex: 'roomId',
        key: 'roomId',
        width: '6%',
      },
      {
        title: 'Room Type',
        dataIndex: 'roomType',
        key: 'roomType;',
        filteredValue: [this.state.searchedText],
        onFilter: (value, record) => {
          return (
            String(record.roomId).toLowerCase().includes(value.toLowerCase()) ||
            String(record.roomType).toLowerCase().includes(value.toLowerCase()) ||
            String(record.roomNo).toLowerCase().includes(value.toLowerCase()) ||
            String(record.hotelId).toLowerCase().includes(value.toLowerCase()) ||
            String(record.hotelName).toLowerCase().includes(value.toLowerCase()) ||
            String(record.totalBeds).toLowerCase().includes(value.toLowerCase())
          );
        },
      },
      {
        title: 'Room No',
        dataIndex: 'roomNo',
        key: 'roomNo',
      },
      {
        title: 'Beds / Room',
        dataIndex: 'totalBeds',
        key: 'totalBeds',
      },
      // {
      //   title: 'Hotel ID',
      //   dataIndex: 'hotelId',
      //   key: 'hotelId',
      //   width: '8%',
      // },
      {
        title: 'Hotel Name',
        dataIndex: 'hotelName',
        key: 'hotelName',
        width: '20%',
      },
      {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        width: '15%',
      },
      {
        title: 'Occupied',
        dataIndex: 'isAssigned',
        key: 'isAssigned',
      },
      {
        title: 'Action',
        key: 'action',
        width: '15%',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.roomId)}>
                View
              </BaseButton>

              <BaseButton severity="info" size="small" onClick={() => this.edit(record.roomId)}>
                Edit
              </BaseButton>

              <BaseButton type="primary" danger size="small" onClick={() => this.delete(record.roomId)}>
                Delete
              </BaseButton>
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <div className="page-Container">
        <S.Card
          id="basic-table"
          title="Room List"
          padding="1.25rem 1.25rem 0"
          extra={
            <BaseButton
              type="info"
              className="btn btn-dark"
              onClick={this.save}
              size="small"
              icon={<PlusCircleOutlined />}
              style={{}}
            >
              Add
            </BaseButton>
          }
        >
          <div className="search-box">
            <ST.InputsWrapper>
              <SearchInput
                placeholder="Search..."
                onChange={(e) => {
                  this.setState(
                    {
                      searchedText: e.target.value,
                    },
                    () => {
                      console.log('New state in ASYNC callback:', this.state.searchedText);
                      this.loadRecords(this.state.searchedText);
                    },
                  );
                }}
                style={{
                  width: 230,
                  marginBottom: 10,
                }}
                allowClear
                size="small"
              />
            </ST.InputsWrapper>
          </div>
          <BaseTable
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              total: totalElements,
              showTotal: (showTotal) => (
                <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {showTotal} records</span>
              ),
              onChange: (page) => this.loadRecords(page - 1),
            }}
            loading={this.props.loading}
            bordered
            scroll={{ x: true }}
            rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          />
        </S.Card>
        {/* <Modal
                        open={this.state.open}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        okButtonProps={{ disabled: true }}
                        cancelButtonProps={{ disabled: true }}
                        width={1000}
                        style={{ top: 20 }}
                    >
                        <Row>
                            <Descriptions title="Service Info" layout="vertical" bordered >
                                <Descriptions.Item label="Service Id">
                                    {this.state.viewRecord.id}
                                </Descriptions.Item>
                                <Descriptions.Item  label="Service Name">
                                    {this.state.viewRecord.serviceName}
                                </Descriptions.Item>
                                <Descriptions.Item label="Descriptions">
                                    {this.state.viewRecord.descriptions}
                                </Descriptions.Item>
                                <Descriptions.Item label="Parent Service">
                                    {this.state.parentServiceName.serviceName}
                                </Descriptions.Item>
                                <Descriptions.Item label="Record Status">
                                    {this.state.viewRecord.status}
                                </Descriptions.Item>
                                <Descriptions.Item label="Activated">
                                    <Badge status="success" text={this.state.viewRecord.isActive} />
                                </Descriptions.Item>
                                <Descriptions.Item label="Created Date" time>
                                    {moment(this.state.viewRecord.createdDate).format('YYYY-MM-DD')}
                                </Descriptions.Item>
                                <Descriptions.Item label="Updated Date" span={2}>
                                    {moment(this.state.viewRecord.updatedData).format('YYYY-MM-DD')}
                                </Descriptions.Item>
                                <Descriptions.Item label="Created By">
                                    {this.state.viewRecord.createdBy}
                                </Descriptions.Item>
                                <Descriptions.Item label="Modified By">
                                    {this.state.viewRecord.modifiedBy}
                                </Descriptions.Item>
                                <Descriptions.Item label="Version">
                                    {this.state.viewRecord.version}
                                </Descriptions.Item>
                            </Descriptions>
                        </Row>
                    </Modal> */}
      </div>
    );
  }
}
