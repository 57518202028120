import React, { Component, useState } from 'react';
import { getRecords, saveRecord } from '@app/utils/APIUtils';
import {
  ACTIVITY_NAME_MIN_LENGTH,
  ACTIVITY_NAME_MAX_LENGTH,
  USERNAME_MIN_LENGTH,
  USERNAME_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
} from '@app/constants';
import { Form, Input, Select } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;
const { TextArea } = Input;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  return <AddCategory {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} form={form} />;
}

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentCategoryList: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    this.props.setLoading(true);

    let promise;

    promise = getRecords('/service/v1/categories/all');

    promise
      .then((response) => {
        this.setState({
          parentCategoryList: response.map((item) => ({ value: item.id, label: item.CategoryName })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.props.setLoading(false);
      });
  }

  handleSubmit(values) {
    const saveRequest = Object.assign({}, values);
    saveRecord(saveRequest, '/service/v1/categories')
      .then((response) => {
        if (response)
          notificationController.success({
            message: 'Category created',
          });
        this.props.navigation('/category/list');
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigation('/category/list');
  }

  render() {
    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="category" title="Add Category" padding="1.25rem">
          <Form
            form={this.props.form}
            onFinish={this.handleSubmit}
            className="category-form"
            name="category"
            labelCol={{ span: 6 }}
            //wrapperCol={{ span: 16 }}
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem
              label="Parent Category"
              name="parentCategoryId"
              rules={[
                {
                  required: true,
                  message: 'Parent category required!',
                },
              ]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select"
                size="small"
                onKeyDown={(e) => handleEnter(e)}
                optionFilterProp="children"
                onSearch={onSearch}
              >
                {this.state.parentCategoryList.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              label="Category Name"
              name="categoryName"
              rules={[
                {
                  required: true,
                  message: 'Category name required!',
                },
              ]}
              className="ant-latest-form-item"
            >
              <Input
                size="small"
                autoComplete="off"
                placeholder="Enter category name"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem
              label="Description"
              name="description"
              rules={[{ required: false, message: 'Description is required!' }]}
              className="ant-latest-form-item"
            >
              <TextArea
                autoComplete="off"
                size="small"
                placeholder="Enter descriptions"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem
              wrapperCol={{
                offset: 6,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }

  // Validation Functions

  validateActivityName = (inputValue) => {
    if (inputValue.length < ACTIVITY_NAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Activity Name is too short (Minimum ${ACTIVITY_NAME_MIN_LENGTH} characters needed.)`,
      };
    } else if (inputValue.length > ACTIVITY_NAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `Activity Name is too long (Maximum ${ACTIVITY_NAME_MAX_LENGTH} characters allowed.)`,
      };
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
  };

  validateDescriptions = (descriptions) => {
    if (!descriptions) {
      return {
        validateStatus: 'error',
        errorMsg: 'Descriptions may not be empty',
      };
    }

    if (descriptions.length > DESCRIPTION_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Descriptions is too long (Maximum ${DESCRIPTION_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validatePrerequisites = (prerequisites) => {
    if (prerequisites.length < USERNAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `prerequisites is too short (Minimum ${USERNAME_MIN_LENGTH} characters needed.)`,
      };
    } else if (prerequisites.length > USERNAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `prerequisites is too long (Maximum ${USERNAME_MAX_LENGTH} characters allowed.)`,
      };
    } else {
      return {
        validateStatus: null,
        errorMsg: null,
      };
    }
  };

  validateNotes = (notes) => {
    if (notes.length < USERNAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Notes is too short (Minimum ${USERNAME_MIN_LENGTH} characters needed.)`,
      };
    } else if (notes.length > USERNAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `Notes is too long (Maximum ${USERNAME_MAX_LENGTH} characters allowed.)`,
      };
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
  };
}
