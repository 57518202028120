import React, { Component, useState } from 'react';
import { getRecords, updateRecord, getRecordById } from '@app/utils/APIUtils';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  return <EditExpense {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} params={params} />;
}

class EditExpense extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(EditExpenseForm);
    return (
      <div>
        <AntWrappedOfficeForm
          dataId={this.props.params}
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class EditExpenseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expenseId: this.props.dataId.id,
      categoryList: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    this.props.setLoading(true);

    let promise, promise1;

    promise = getRecords('/domain/data/expense/category/v1/list');
    promise1 = getRecordById('/setup/v1/expenses/', this.state.expenseId);

    promise
      .then((response) => {
        this.setState({
          categoryList: response.map((item) => ({ value: item.id, label: item.CategoryName })),
        });
      })
      .catch((error) => {
        if (error) this.props.setLoading(false);
      });

    promise1
      .then((res) => {
        this.props.form.setFieldsValue({
          expenseName: res.ExpenseName,
          categoryId: res.CategoryBean.id,
          amount: res.Amount,
          comments: res.Comments,
        });
      })
      .then((data) => {
        this.setState({
          data: [data],
        });
      });

    Promise.all([promise, promise1]);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const updateRequest = Object.assign({}, values);
        console.log('updateRequest => ' + JSON.stringify(updateRequest));

        updateRecord(this.state.expenseId, updateRequest, '/setup/v1/expenses/')
          .then((response) => {
            if (response.statusCode === 200) {
              notificationController.success({
                message: 'Expense updated.',
              });
              this.props.navigate('/expense/list');
            }
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/expense/list');
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Edit Expense" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="bus-form"
            name="basic"
            labelCol={{ span: 6 }}
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem label="Expense Name">
              {getFieldDecorator('expenseName', {
                rules: [{ required: true, message: 'Please input expense name!' }],
              })(
                <Input
                  size="small"
                  name="expenseName"
                  autoComplete="off"
                  placeholder="Enter expense name"
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Category">
              {getFieldDecorator('categoryId', {
                rules: [{ required: true, message: 'Please select category!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Please select"
                  name="categoryId"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.categoryList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Amount">
              {getFieldDecorator('amount', {
                rules: [{ required: true, message: 'Please input amount!' }],
              })(
                <Input
                  size="small"
                  name="amount"
                  type="number"
                  autoComplete="off"
                  onKeyDown={(e) => handleEnter(e)}
                  placeholder="Enter expense amount"
                />,
              )}
            </FormItem>

            <FormItem label="Comments">
              {getFieldDecorator('comments', {
                rules: [{ required: false, message: 'Please input comments!' }],
              })(
                <Input
                  size="small"
                  name="comments"
                  autoComplete="off"
                  onKeyDown={(e) => handleEnter(e)}
                  placeholder="Enter comments"
                />,
              )}
            </FormItem>

            <FormItem
              wrapperCol={{
                offset: 6,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }
}
