import React, { Component, useState } from 'react';
import { getRecordById, updateRecord, getRecords } from '@app/utils/APIUtils';
import { Input, Select, DatePicker, Descriptions } from 'antd';
import Moment from 'moment';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  return (
    <EditRegistration {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} params={params} />
  );
}

class EditRegistration extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(EditRegistrationForm);
    return (
      <div>
        <AntWrappedOfficeForm
          dataId={this.props.params}
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class EditRegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      registrationId: this.props.dataId.id,
      hajYearList: [],
      regCustomerList: [],
      isFeaturd: false,
      customerInfo: {},
      custName: '',
      mobileNo: '',
      isReplaced: false,
      regDateOpen: false,
      hidden: true,
      isRequired: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleChangeIsReplaced = (e) => {
    const { checked } = e.target;
    this.setState({
      isReplaced: checked,
    });
    if (checked === true) {
      this.setState({
        hidden: false,
        isRequired: true,
      });
    } else {
      this.setState({
        hidden: true,
        isRequired: false,
      });
    }
  };

  componentDidMount() {
    let promise1, promise2, promise3;

    promise1 = getRecords('/domain/data/hajyears/v1/active/all');
    promise2 = getRecordById('/public/customer/v1/registered/', this.state.registrationId);
    promise3 = getRecords('/public/customer/v1/registered/list');

    this.setState({
      isLoading: true,
    });

    promise1
      .then((response) => {
        this.setState({
          hajYearList: response.map((item) => ({ value: item.id, label: item.ceYear + ' - (' + item.hijriYear + ')' })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.setState({
          isLoading: false,
        });
      });

    promise2.then((res) => {
      //To check isReplaced
      if (res.isReplaced === true) {
        this.setState({
          isReplaced: true,
          hidden: false,
          isRequired: true,
        });
      } else {
        this.setState({
          isReplaced: false,
          hidden: true,
          isRequired: false,
        });
      }

      this.props.form.setFieldsValue({
        customerId: res.customerId,
        hajYearId: res.hajYearId,
        hajSerialNo: res.hajSerial,
        registrationDate: Moment(res.registrationDate),
        regVoucherNo: res.regVoucherNo,
        pilgrimId: res.pilgrimId,
        biometricsId: res.biometricsId,
        ehajTag: res.ehajTag,
        visaNo: res.visaNo,
        //isReplaced: res.isReplaced,
        newCustomerId: res.replacedRef,
      });

      getRecords(`/public/customer/v1/pregistered/${res.customerId}/cif`)
        .then((response) => {
          if (response.statusCode === 204) {
            notificationController.info({
              message: 'Customer not found!',
            });
          } else {
            this.setState({
              customerInfo: response,
            });
          }
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
        });

      promise3
        .then((response) => {
          this.setState({
            regCustomerList: response.map((item) => ({ value: item.id, label: item.custName })),
          });
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
          this.setState({
            isLoading: false,
          });
        });
    });

    Promise.all([promise1, promise2, promise3]);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //const updateRequest = Object.assign({}, values);
        const updateRequest = {
          customerId: values.customerId,
          hajYearId: values.hajYearId,
          hajSerialNo: values.hajSerialNo,
          registrationDate: Moment(values.registrationDate).format('YYYY-MM-DD'),
          regVoucherNo: values.regVoucherNo,
          pilgrimId: values.pilgrimId,
          biometricsId: values.biometricsId,
          ehajTag: values.ehajTag,
          visaNo: values.visaNo,
          isReplaced: this.state.isReplaced,
          newCustomerId: values.newCustomerId,
        };
        //updateRequest.referenceStatusId  = 111;
        console.log('updateRequest => ' + JSON.stringify(updateRequest));

        updateRecord(this.state.registrationId, updateRequest, '/public/customer/v1/registered/')
          .then((response) => {
            if (response.statusCode === 110) {
              notificationController.info({
                message: 'Haj year must be current year!',
              });
            } else {
              notificationController.success({
                message: 'Reg info updated.',
              });
              this.props.navigate('/registration/list');
            }
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/registration/list');
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { customerInfo, hidden, isRequired } = this.state;
    const onChangeDate = (date, dateString) => {
      console.log(date, dateString);
    };

    return (
      <BaseCard id="validation form" title="Edit Registration" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              onSubmit={this.handleSubmit}
              className="registration-form"
              name="basic"
              labelCol={{ span: 8 }}
              style={{ maxWidth: 1000 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem label="Customer ID">
                {getFieldDecorator('customerId', {
                  rules: [{ required: true, message: 'Customer id is required!' }],
                })(<Input name="customerId" size="small" disabled={true} />)}
              </FormItem>

              <FormItem label="Haj Year">
                {getFieldDecorator('hajYearId', {
                  rules: [{ required: true, message: 'Haj Year is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select Haj Year"
                    name="hajYearId"
                    size="small"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.hajYearList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Haj Serial No">
                {getFieldDecorator('hajSerialNo', {
                  rules: [{ required: true, message: 'Haj serial no is required!' }],
                })(
                  <Input
                    name="hajSerialNo"
                    placeholder="Enter haj serial no"
                    size="small"
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Registration Date">
                {getFieldDecorator('registrationDate', {
                  rules: [{ required: true, message: 'Registration date is required!' }],
                })(
                  <DatePicker
                    name="registrationDate"
                    size="small"
                    placeholder="Select Date"
                    onChange={onChangeDate}
                    onKeyDown={(e) => handleEnter(e)}
                    format={this.state.regDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
                    onOpenChange={(status) => {
                      this.setState({ regDateOpen: status });
                    }}
                  />,
                )}
              </FormItem>

              <FormItem label="Reg Voucher No">
                {getFieldDecorator('regVoucherNo', {
                  rules: [{ required: false, message: 'Reg voucher no is required!' }],
                })(
                  <Input
                    name="regVoucherNo"
                    placeholder="Enter registration voucher no"
                    size="small"
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Pilgrim ID">
                {getFieldDecorator('pilgrimId', {
                  rules: [{ required: false, message: 'Pilgrim ID is required!' }],
                })(
                  <Input
                    name="pilgrimId"
                    size="small"
                    placeholder="Enter pilgrim ID"
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Biometrics Enrollment ID">
                {getFieldDecorator('biometricsId', {
                  rules: [{ required: false, message: 'Biometrics id is required!' }],
                })(
                  <Input
                    name="biometricsId"
                    placeholder="Enter biometrics enrollment ID"
                    size="small"
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="E-Haj Tag">
                {getFieldDecorator('ehajTag', {
                  rules: [{ required: false, message: 'E-Haj tag is required!' }],
                })(
                  <Input
                    name="ehajTag"
                    size="small"
                    placeholder="Enter E-Haj tag"
                    tabIndex={6}
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Visa No">
                {getFieldDecorator('visaNo', {
                  rules: [{ required: false, message: 'Visa no is required!' }],
                })(
                  <Input
                    name="visaNo"
                    size="small"
                    placeholder="Enter visa no"
                    tabIndex={6}
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Replaced">
                {getFieldDecorator('isReplaced', {
                  rules: [{ required: false, message: 'Replaced is required!' }],
                })(
                  <BaseCheckbox
                    onChange={(e) => this.handleChangeIsReplaced(e)}
                    checked={this.state.isReplaced}
                    onKeyDown={(e) => handleEnter(e)}
                  ></BaseCheckbox>,
                )}
              </FormItem>

              <FormItem label="By Customer" hidden={hidden}>
                {getFieldDecorator('newCustomerId', {
                  rules: [{ required: isRequired, message: 'By customer is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select customer"
                    name="newCustomerId"
                    size="small"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.regCustomerList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 8,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} />
              </FormItem>
            </Form>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <Descriptions title="Customer pre-registartion info" bordered size="middle" key={customerInfo.id}>
              <Descriptions.Item label="Customer Name" span={3}>
                {customerInfo.customerName}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile No" span={3}>
                {customerInfo.mobileNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Pre-Registration Date" span={3}>
                {customerInfo.preRegistrationDate}
              </Descriptions.Item>
              <Descriptions.Item label="Serial No" span={3}>
                {customerInfo.serialNo}
              </Descriptions.Item>
              <Descriptions.Item label="Tracking No" span={3}>
                {customerInfo.trackingNo}
              </Descriptions.Item>
              <Descriptions.Item label="Bank Info" span={3}>
                {customerInfo.bankInfo}
              </Descriptions.Item>
            </Descriptions>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}
