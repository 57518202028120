import React, { Component, useState } from 'react';
import { Input, Descriptions, Spin } from 'antd';
import { ACCESS_TOKEN, API_BASE_URL } from '@app/constants';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { searchRecord } from '@app/utils/APIUtils';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';

const FormItem = Form.Item;

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const [spinning, setSpinning] = React.useState(false);
  const navigate = useNavigate();
  return (
    <CustomerIdCard
      {...props}
      navigation={navigate}
      isLoading={isLoading}
      setLoading={setLoading}
      spinning={spinning}
      setSpinning={setSpinning}
    />
  );
}

class CustomerIdCard extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(CustomerIdCardForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
          spinning={this.props.spinning}
          setSpinning={this.props.setSpinning}
        />
      </div>
    );
  }
}

class CustomerIdCardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      response: {},
      mobileNumber: {
        value: '',
      },
      fileFormat: {
        value: '',
      },
      nationalId: {
        value: '',
      },
      passportNo: {
        value: '',
      },
      tags: {
        value: '',
      },
      customerInfo: {},
      districtName: '',
      thanaName: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleKeyDown(event) {
    event.preventDefault();

    this.props.form.validateFields((_err, values) => {
      const searchRequest = {
        mobileNumber: values.mobileNumber,
      };

      console.log('searchRequest => ' + JSON.stringify(searchRequest));

      searchRecord(searchRequest, '/public/v1/customers/cif')
        .then((response) => {
          if (response.statusCode === 204) {
            notificationController.error({
              message: 'No record found',
            });
          } else {
            this.setState({
              customerInfo: response,
              districtName: response.DistrictBean.districtNameEn,
              thanaName: response.ThanBean.thanaNameEn,
            });
          }
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
        });
    });
  }

  handleSubmit(event) {
    this.props.setSpinning(true);
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const paramRequest = Object.assign({}, values);
        console.log('paramRequest => ' + JSON.stringify(paramRequest));

        fetch(API_BASE_URL + '/public/v1/customers/generate/idcard', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
          },
          body: JSON.stringify(paramRequest),
        })
          .then(async (response) => {
            if (response.ok) {
              let blob;
              const reportBytes = await response.arrayBuffer();
              blob = new Blob([reportBytes], { type: 'application/pdf' });
              const reportUrl = URL.createObjectURL(blob);

              window.open(reportUrl, '_blank');

              notificationController.success({
                message: 'ID card generated',
              });
            }
            this.props.setSpinning(false);
          })
          .catch(function (error) {
            this.props.setSpinning(false);
            notificationController.error({
              message: 'Failed' + error.message,
            });
          });
      }
    });
    event.preventDefault();
    this.props.form.resetFields();
    this.setState({
      customerInfo: '',
      districtName: '',
      thanaName: '',
    });
  }

  clearForm(event) {
    event.preventDefault();
    this.props.form.resetFields();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { customerInfo, districtName, thanaName } = this.state;

    return (
      <Spin spinning={this.props.spinning} fullscreen="true" size="large" tip="Loading...">
        <BaseCol xs={24} sm={24} xl={24}>
          <BaseCard id="idcard" title="Customer > ID Card Generation" padding="1.25rem">
            <BaseRow gutter={[30, 30]}>
              <BaseCol xs={24} sm={20} xl={12}>
                <Form
                  onSubmit={this.handleSubmit}
                  className="report-form"
                  name="basic"
                  labelCol={{ span: 6 }}
                  //wrapperCol={{ span: 16 }}
                  style={{ maxWidth: 500 }}
                  initialValues={{ remember: true }}
                  autoComplete="off"
                >
                  <FormItem label="Search Criteria"></FormItem>

                  <FormItem label="Mobile Number">
                    {getFieldDecorator('mobileNumber', {
                      rules: [{ required: false, message: 'Mobile number is required!' }],
                    })(
                      <Input
                        addonBefore="+88"
                        name="mobileNumber"
                        size="small"
                        placeholder="Enter mobile number"
                        maxLength={11}
                        value={this.state.mobileNumber.value}
                        onBlur={this.handleKeyDown}
                      />,
                    )}
                  </FormItem>

                  <FormItem></FormItem>

                  <FormItem
                    wrapperCol={{
                      offset: 6,
                      span: 9,
                    }}
                  >
                    <BaseRow gutter={[10, 10]} wrap={false}>
                      <BaseCol span={12} xs={17} sm={12} xl={12}>
                        <BaseButton block type="primary" htmlType="submit" size="small">
                          Download
                        </BaseButton>
                      </BaseCol>
                      <BaseCol span={12} xs={17} sm={12} xl={12}>
                        <BaseButton block type="ghost" onClick={this.clearForm} size="small">
                          Reset
                        </BaseButton>
                      </BaseCol>
                    </BaseRow>
                  </FormItem>
                </Form>
              </BaseCol>

              <BaseCol xs={24} sm={12} xl={12}>
                <Descriptions title="Customer Info" bordered size="middle">
                  <Descriptions.Item label="Avatar" span={3}>
                    <BaseAvatar
                      shape="circle"
                      src={`data:image/png;base64,${customerInfo?.File}`}
                      alt="Profile"
                      /* icon={<img src={UserImage} alt="default profile img" />} */
                      style={{ height: 164, width: 164 }}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Customer Name" span={3}>
                    {customerInfo.CustName}
                  </Descriptions.Item>
                  <Descriptions.Item label="Customer Email" span={3}>
                    {customerInfo.Email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Mobile No" span={3}>
                    {customerInfo.MobileNo}
                  </Descriptions.Item>
                  <Descriptions.Item label="Address" span={3}>
                    {customerInfo.AddressLine1}
                  </Descriptions.Item>
                  <Descriptions.Item label="District" span={3}>
                    {districtName}
                  </Descriptions.Item>
                  <Descriptions.Item label="Thana" span={3}>
                    {thanaName}
                  </Descriptions.Item>
                </Descriptions>
              </BaseCol>
            </BaseRow>
          </BaseCard>
        </BaseCol>
      </Spin>
    );
  }
}
