import React, { Component, useState } from 'react';
import { deleteItemByID, getRecordSet } from '@app/utils/APIUtils';
import { Modal, Row, Descriptions, Badge } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import moment from 'moment';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';

export default function (props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  return (
    <DocumentList
      {...props}
      loading={loading}
      setLoading={setLoading}
      page={page}
      setPage={setPage}
      navigate={navigate}
    />
  );
}

class DocumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      response: {},
      searchedText: '',
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
    };
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
  }

  loadRecords(searchedText, page, size = LIST_SIZE) {
    this.setState({
      recordSet: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      isLoading: false,
    });

    this.props.setLoading(true);

    let promise = getRecordSet(page, size, '/customer/v1/documents?page=', searchedText);

    if (!promise) {
      return;
    }

    promise
      .then((response) => {
        const recordSet = this.state.recordSet.slice();

        if (!(searchedText === '' || searchedText === undefined)) {
          this.setState({
            recordSet: recordSet.concat(response.content),
            page: response.page,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            last: response.last,
          });

          this.props.setLoading(false);
        } else {
          setTimeout(() => {
            this.setState({
              recordSet: recordSet.concat(response.content),
              page: response.page,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              last: response.last,
            });

            this.props.setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.props.setLoading(false);
      });
    console.log('Component mounted.');
  }

  componentDidMount() {
    this.loadRecords();
  }

  save() {
    this.props.navigate('/customer/doc/new');
  }

  edit(id) {
    this.props.navigate(`/customer/doc/edit/${id}`);
  }

  delete(id) {
    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);

          //onRemoveFunctionality here
          deleteItemByID(id, '/customer/v1/documents/')
            .then((res) => {
              if (res.statusCode === 200) {
                this.setState({ recordSet: this.state.recordSet.filter((record) => record.fileId !== id) });
                notificationController.success({
                  message: 'File deleted.',
                });
              }
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
              this.props.setLoading(false);
            });
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  }

  view(id) {
    this.props.navigate(`/customer/doc/edit/${id}`);
  }

  render() {
    const { recordSet, totalElements } = this.state;

    //const vendorViews = [];
    const data = [];
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        fileId: record.fileId,
        customerId: record.cusId,
        customerName: record.cusName,
        docTypeName: record.docTypeName,
        fileName: record.fileName,
      });
    });

    const columns = [
      {
        title: 'File ID',
        dataIndex: 'fileId',
        key: 'fileId',
        width: '7%',
      },
      {
        title: 'CIF ID',
        dataIndex: 'customerId',
        key: 'customerId',
        width: '10%',
      },
      {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName',
        width: '25%',
      },
      {
        title: 'Document Type',
        dataIndex: 'docTypeName',
        key: 'docTypeName',
        width: '15%',
      },
      {
        title: 'File Name',
        dataIndex: 'fileName',
        key: 'fileName;',
        width: '25%',
      },
      {
        title: 'Action',
        key: 'action',
        width: '15%',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.fileId)}>
                View
              </BaseButton>

              <BaseButton severity="info" size="small" onClick={() => this.edit(record.fileId)}>
                Edit
              </BaseButton>

              <BaseButton type="primary" danger size="small" onClick={() => this.delete(record.fileId)}>
                Delete
              </BaseButton>
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <div className="page-Container">
        <S.Card
          id="basic-table"
          title="Document List"
          padding="1.25rem 1.25rem 0"
          extra={
            <BaseButton
              type="info"
              className="btn btn-dark"
              onClick={this.save}
              size="small"
              icon={<PlusCircleOutlined />}
            >
              Add
            </BaseButton>
          }
        >
          <div className="search-box">
            <ST.InputsWrapper>
              <SearchInput
                placeholder="Search..."
                /*  onSearch={(value) => {
                  this.setState({
                    searchedText: value,
                  });
                }} */
                onChange={(e) => {
                  this.setState(
                    {
                      searchedText: e.target.value,
                    },
                    () => {
                      console.log('New state in ASYNC callback:', this.state.searchedText);
                      this.loadRecords(this.state.searchedText);
                    },
                  );
                }}
                style={{
                  width: 230,
                  marginBottom: 10,
                }}
                allowClear
                size="small"
              />
            </ST.InputsWrapper>
          </div>
          <BaseTable
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              total: totalElements,
              showTotal: (showTotal) => (
                <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {showTotal} records</span>
              ),
              onChange: (page) => this.loadRecords(page - 1),
            }}
            loading={this.props.loading}
            bordered
            scroll={{ x: true }}
            rowClassName={(_record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          />
        </S.Card>

        <div>
          {this.state.viewRecord ? (
            <Modal
              open={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              okButtonProps={{ disabled: true }}
              cancelButtonProps={{ disabled: true }}
              width={1000}
              style={{ top: 20 }}
            >
              <Row>
                <Descriptions title="Agent Info" layout="vertical" bordered>
                  <Descriptions.Item label="Agent Id">{this.state.viewRecord.id}</Descriptions.Item>
                  <Descriptions.Item label="Agent Type">{this.state.viewRecord.agentTypeId}</Descriptions.Item>
                  <Descriptions.Item label="Agent Name">{this.state.viewRecord.agentName}</Descriptions.Item>
                  <Descriptions.Item label="License No">{this.state.viewRecord.licenseNo}</Descriptions.Item>
                  <Descriptions.Item label="Email">{this.state.viewRecord.email}</Descriptions.Item>
                  <Descriptions.Item label="Phone No">{this.state.viewRecord.phoneNumber}</Descriptions.Item>
                  <Descriptions.Item label="Mobile No">{this.state.viewRecord.mobileNumber}</Descriptions.Item>
                  <Descriptions.Item label="Contact Name">{this.state.viewRecord.contactName}</Descriptions.Item>

                  <Descriptions.Item label="District Name">{this.state.district}</Descriptions.Item>
                  <Descriptions.Item label="Thana">{this.state.thana}</Descriptions.Item>
                  <Descriptions.Item label="Address">{this.state.viewRecord.addressLineOne}</Descriptions.Item>
                  <Descriptions.Item label="Parent Agent">
                    {/* {this.state.parentServiceName.serviceName}*/}
                  </Descriptions.Item>
                  <Descriptions.Item label="Record Status">{this.state.viewRecord.status}</Descriptions.Item>
                  <Descriptions.Item label="Activated">
                    <Badge status="success" text={this.state.viewRecord.isActive} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Created Date" time>
                    {moment(this.state.viewRecord.createdDate).format('YYYY-MM-DD')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Updated Date">
                    {moment(this.state.viewRecord.updatedData).format('YYYY-MM-DD')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Created By">{this.state.viewRecord.createdBy}</Descriptions.Item>
                  <Descriptions.Item label="Modified By">{this.state.viewRecord.modifiedBy}</Descriptions.Item>
                  <Descriptions.Item label="Version">{this.state.viewRecord.version}</Descriptions.Item>
                </Descriptions>
              </Row>
            </Modal>
          ) : null}
        </div>
      </div>
    );
  }
}
