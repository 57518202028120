import React, { Component, useState } from 'react';
import { Select, Radio, Space, Spin } from 'antd';
import { ACCESS_TOKEN, API_BASE_URL } from '@app/constants';
import { getRecords } from '@app/utils/APIUtils';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

const FormItem = Form.Item;
const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const [spinning, setSpinning] = React.useState(false);
  const navigate = useNavigate();
  return (
    <CustomerReport
      {...props}
      navigation={navigate}
      isLoading={isLoading}
      setLoading={setLoading}
      spinning={spinning}
      setSpinning={setSpinning}
    />
  );
}

class CustomerReport extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(CustomerReportForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
          spinning={this.props.spinning}
          setSpinning={this.props.setSpinning}
        />
      </div>
    );
  }
}

class CustomerReportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      response: {},
      recordStat: {
        value: '',
      },
      fileFormat: {
        value: '',
      },
      recStatus: [
        { value: 1, label: 'Prospect' },
        { value: 2, label: 'Pre-registered' },
        { value: 3, label: 'Registered' },
        { value: 4, label: 'Transferred In' },
        { value: 5, label: 'Transferred Out' },
        { value: 6, label: 'Blocked' },
        { value: 7, label: 'Archieved' },
      ],
      allianceList: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise;

    promise = getRecords('/admin/v1/agents/user/alliances');

    this.setState({
      isLoading: true,
    });

    promise.then((response) => {
      this.setState({
        allianceList: response.map((item) => ({ value: item.dataPath, label: item.agentName })),
      });
    })
    .catch((error) => {
      if (error)
        this.setState({
          isLoading: false,
        });
    });

    Promise.all([promise]);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.setSpinning(true);

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const paramRequest = Object.assign({}, values);
        console.log('paramRequest => ' + JSON.stringify(paramRequest));

        fetch(API_BASE_URL + '/public/v1/customers/preview', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
          },
          body: JSON.stringify(paramRequest),
          responseType: 'blob',
        }).then(async (response) => {
          if (response.ok) {
            var filename = '';
            var contentDisposition = response.headers.get('Content-Disposition');
            if (contentDisposition && contentDisposition.indexOf('inline') !== -1) {
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(contentDisposition);
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
              }
            }
            let blob;
            var fileExt = filename.split('.').pop();
            const reportBytes = await response.arrayBuffer();

            if (fileExt === 'pdf') {
              blob = new File([new Uint8Array(reportBytes)], filename, {
                type: 'application/pdf',
              });
            } else if (fileExt === 'xls') {
              blob = new File([new Uint8Array(reportBytes)], filename, {
                type: 'application/vnd.ms-excel',
              });
            }
            const reportUrl = URL.createObjectURL(blob);

            //Open report on new TAB
            window.open(reportUrl, 'newtab');
            //Optional
            /* if (fileExt === 'pdf'){
              // Create an anchor to trigger download
              const a = document.createElement('a');
              a.href = reportUrl;
              a.download = blob.name;  // Force download with the custom name
              a.click();

              // Clean up
              URL.revokeObjectURL(reportUrl);
            } */
            notificationController.success({
              message: 'Report generated',
            });
          }
          this.props.setSpinning(false);
        })
        .catch(function (error) {
          this.props.setSpinning(false);
          notificationController.error({
            message: 'Failed' + error.message,
          });
        });
      }
    });
  }

  clearForm(event) {
    event.preventDefault();
    this.props.form.resetFields();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin spinning={this.props.spinning} fullscreen="true" size="large" tip="Loading...">
        <BaseCol xs={24} sm={24} xl={24}>
          <BaseCard id="validation form" title="Reports > Customers List" padding="1.25rem">
            <Form
              onSubmit={this.handleSubmit}
              className="report-form"
              name="basic"
              labelCol={{ span: 7 }}
              //wrapperCol={{ span: 16 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem label="Filtering Criteria"></FormItem>

              <FormItem label="Report Selection">
                {getFieldDecorator('reportValue', {
                  initialValue: 1,
                  rules: [{ required: true, message: 'Please select report!' }],
                })(
                  <Radio.Group name="reportValue">
                    <Space direction="vertical">
                      <Radio value={1}>Customers list</Radio>
                      <Radio value={2}>Customers with valid passport</Radio>
                      <Radio value={3}>Customers with expiry passport</Radio>
                    </Space>
                  </Radio.Group>,
                )}
              </FormItem>

              <FormItem label="Agent / Sub-agent">
                {getFieldDecorator('allianceDataPath', {
                  rules: [{ required: false, message: 'Alliance is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select Alliance"
                    name="allianceDataPath"
                    size="small"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                    defaultValue={0}
                  >
                    <Select.Option value={0}> ALL </Select.Option>
                    {this.state.allianceList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Status">
                {getFieldDecorator('recordStat', {
                  rules: [{ required: false, message: 'Status is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select status"
                    name="recordStat"
                    size="small"
                    value={this.state.recordStat.value}
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                    defaultValue={0}
                  >
                    <Select.Option value={0}> ALL </Select.Option>
                    {this.state.recStatus.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Export Option">
                {getFieldDecorator('fileFormat', {
                  initialValue: 'pdf',
                  rules: [{ required: true, message: 'Please select report format!' }],
                })(
                  <Radio.Group name="fileFormat">
                    <Radio value="pdf">PDF</Radio>
                    <Radio value="xls">XLS</Radio>
                  </Radio.Group>,
                )}
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 7,
                  span: 9,
                }}
              >
                <BaseRow gutter={[10, 10]} wrap={false}>
                  <BaseCol span={12} xs={17} sm={12} xl={12}>
                    <BaseButton block type="primary" htmlType="submit" size="small">
                      Submit
                    </BaseButton>
                  </BaseCol>
                  <BaseCol span={12} xs={17} sm={12} xl={12}>
                    <BaseButton block type="ghost" onClick={this.clearForm} size="small">
                      Reset
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              </FormItem>
            </Form>
          </BaseCard>
        </BaseCol>
      </Spin>
    );
  }
}
