import React, { Component, useState } from 'react';
import { getRecordById, getRecords, saveRecord, checkRecordAvailability } from '@app/utils/APIUtils';
import {
  AGENTNAME_MIN_LENGTH,
  AGENTNAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  MOBILE_MAX_LENGTH,
} from '@app/constants';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  return <AddAgent {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} />;
}

class AddAgent extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(AddAgentForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class AddAgentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentId: {
        value: '',
      },
      agentName: {
        value: '',
      },
      licenseNo: {
        value: '',
      },
      contactName: {
        value: '',
      },
      email: {
        value: '',
      },
      phoneNumber: {
        value: '',
      },
      mobileNumber: {
        value: '',
      },
      addressLineOne: {
        value: '',
      },
      addressLineTwo: {
        value: '',
      },
      agentTypeId: {
        value: '',
      },
      parentAgentId: {
        value: '',
      },
      agentTypeItems: [
        { value: 1, label: 'Lead Agent' },
        { value: 2, label: 'Agent' },
        { value: 3, label: 'Sub-agent' },
        { value: 4, label: 'Individual' },
      ],
      parentAgentList: [],
      districtList: [],
      thanaList: [],
      parentAgentAll: [],
      recordStat: {
        value: '',
      },
      referenceStatusItems: [
        { value: 1, label: 'Approved' },
        { value: 2, label: 'Rejected' },
        { value: 3, label: 'Requested' },
        { value: 4, label: 'Suspended' },
      ],
      isRequired: false,
      isReadonly: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.handleDistrictChange = this.handleDistrictChange.bind(this);
    this.handleAgentTypeChange = this.handleAgentTypeChange.bind(this);
    this.handleAgentIdChange = this.handleAgentIdChange.bind(this);
    this.validateAgentnameAvailability = this.validateAgentnameAvailability.bind(this);
    this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
    this.validateAgentPhoneNumberAvailability = this.validateAgentPhoneNumberAvailability.bind(this);
    this.validateAgentMobileNumberAvailability = this.validateAgentMobileNumberAvailability.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    this.props.setLoading(true);

    let promise1;

    promise1 = getRecords('/domain/data/districts/v1/all');

    promise1
      .then((response) => {
        this.setState({
          districtList: response.map((item) => ({
            value: item.id,
            label: item.districtNameEn + ' - ' + item.districtNameBn,
          })),
        });
      })
      .catch((error) => {
        if (error) this.props.setLoading(false);
      });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.isFormInvalid()) {
          notificationController.error({
            message: 'Duplicate Mobile or Email!',
          });
        } else {
          const saveRequest = Object.assign({}, values);
          //saveRequest.referenceStatusId  = 111;
          console.log('saveRequest => ' + JSON.stringify(saveRequest));

          saveRecord(saveRequest, '/admin/v1/agents')
            .then((response) => {
              if (response.statusCode === 201) {
                notificationController.success({
                  message: 'Agent created.',
                });
                this.props.navigate('/agent/list');
              } else if (response.statusCode === 102) {
                notificationController.error({
                  message: 'User must be an agent!',
                });
              }
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
            });
        }
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/agent/list');
  }

  handleDistrictChange = (value) => {
    try {
      getRecords(`/domain/data/thanas/v1/${value}/district`)
        .then((response) => {
          this.props.form.resetFields(['thana']);
          this.setState({
            thanaList: response.map((item) => ({ value: item.id, label: item.thanaNameEn + ' - ' + item.thanaNameBn })),
          });
        })
        .catch((error) => {
          if (error) this.setState({ thanaList: [] });
          this.setState({
            isLoading: false,
          });
        });
    } catch (error) {}
  };

  handleAgentTypeChange = (value) => {
    try {
      if (value === 2) {
        getRecords('/admin/v1/agents/parent/all')
          .then((response) => {
            this.props.form.resetFields(['parentAgentId']);
            this.setState({
              parentAgentList: response.map((item) => ({ value: item.id, label: item.AgentName })),
              isRequired: true,
            });
          })
          .catch((error) => {
            if (error) this.setState({ parentAgentList: [] });
            this.setState({
              isLoading: false,
            });
          });
      } else if (value === 3 || value === 4) {
        getRecords('/admin/v1/agents/all')
          .then((response) => {
            this.props.form.resetFields(['parentAgentId']);
            this.setState({
              parentAgentList: response.map((item) => ({ value: item.id, label: item.AgentName })),
              isRequired: true,
            });
          })
          .catch((error) => {
            if (error) this.setState({ parentAgentList: [] });
            this.setState({
              isLoading: false,
            });
          });
      } else {
        this.props.form.resetFields(['parentAgentId']);
        this.setState({
          parentAgentList: [],
          isRequired: false,
        });
      }
    } catch (error) {}
  };

  handleAgentIdChange = (value) => {
    try {
      let promise3 = getRecordById('/admin/v1/agents/', value);
      promise3.then((res) => {
        this.props.form.setFieldsValue({
          licenseNo: res.LicenseNo,
        });
      });
      this.setState({
        isReadonly: true,
      });
    } catch (error) {}
  };

  isFormInvalid() {
    return !(
      this.state.agentName.validateStatus === 'success' &&
      this.state.phoneNumber.validateStatus === 'success' &&
      this.state.email.validateStatus === 'success' &&
      this.state.mobileNumber.validateStatus === 'success'
    );
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Add Agent" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="agent-form"
            name="basic"
            labelCol={{ span: 10 }}
            //wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem label="Agent Type">
              {getFieldDecorator('agentTypeId', {
                rules: [{ required: true, message: 'Agent type is required!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Select"
                  name="agentTypeId"
                  value={this.state.agentTypeId.value}
                  onChange={this.handleAgentTypeChange}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.agentTypeItems.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Parent Agent">
              {getFieldDecorator('parentAgentId', {
                rules: [{ required: this.state.isRequired, message: 'Parent agent is required!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Select"
                  name="parentAgentId"
                  value={this.state.parentAgentId.value}
                  /* onChange={value => {
                        this.state.agentTypeId.value = value;
                    }} */
                  onChange={this.handleAgentIdChange}
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.parentAgentList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem
              label="Agent/ Sub-agent/ Individual Name"
              hasFeedback
              validateStatus={this.state.agentName.validateStatus}
              help={this.state.agentName.errorMsg}
            >
              {getFieldDecorator('agentName', {
                rules: [{ required: true, message: 'Agent name is required!' }],
              })(
                <Input
                  size="small"
                  name="agentName"
                  placeholder="Enter agent name"
                  value={this.state.agentName.value}
                  onKeyUpCapture={this.validateAgentnameAvailability}
                  onChange={(event) => this.handleInputChange(event, this.validateAgentname)}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem label="License No">
              {getFieldDecorator('licenseNo', {
                rules: [{ required: true, message: 'License no is required!' }],
              })(
                <Input
                  size="small"
                  name="licenseNo"
                  placeholder="Enter license no"
                  value={this.state.licenseNo.value}
                  readOnly={this.state.isReadonly}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem label="Contact Name">
              {getFieldDecorator('contactName', {
                rules: [{ required: true, message: 'Contact name is required!' }],
              })(
                <Input
                  size="small"
                  name="contactName"
                  placeholder="Enter contact name"
                  value={this.state.contactName.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem
              label="Email"
              hasFeedback
              validateStatus={this.state.email.validateStatus}
              help={this.state.email.errorMsg}
            >
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Email address is required!' }],
              })(
                <Input
                  addonBefore={<MailOutlined />}
                  size="small"
                  name="email"
                  placeholder="Enter valid email address"
                  value={this.state.email.value}
                  onKeyUpCapture={this.validateEmailAvailability}
                  onChange={(event) => this.handleInputChange(event, this.validateEmail)}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem
              label="Phone Number"
              hasFeedback
              validateStatus={this.state.phoneNumber.validateStatus}
              help={this.state.phoneNumber.errorMsg}
            >
              {getFieldDecorator('phoneNumber', {
                rules: [{ required: true, message: 'Contact number is required!' }],
              })(
                <Input
                  addonBefore={<PhoneOutlined />}
                  size="small"
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  maxLength={11}
                  value={this.state.phoneNumber.value}
                  onKeyUpCapture={this.validateAgentPhoneNumberAvailability}
                  onChange={(event) => this.handleInputChange(event, this.validatePhone)}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem
              label="Mobile Number"
              hasFeedback
              validateStatus={this.state.mobileNumber.validateStatus}
              help={this.state.mobileNumber.errorMsg}
            >
              {getFieldDecorator('mobileNumber', {
                rules: [{ required: true, message: 'Mobile no is required!' }],
              })(
                <Input
                  size="small"
                  addonBefore="+88"
                  name="mobileNumber"
                  placeholder="Enter mobile number"
                  maxLength={11}
                  value={this.state.mobileNumber.value}
                  onKeyUpCapture={this.validateAgentMobileNumberAvailability}
                  onChange={(event) => this.handleInputChange(event, this.validateMobile)}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem label="District">
              {getFieldDecorator('district', {
                rules: [{ required: true, message: 'District is required!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Select"
                  name="district"
                  onChange={this.handleDistrictChange}
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.districtList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>
            <FormItem label="Thana">
              {getFieldDecorator('thana', {
                rules: [{ required: true, message: 'Thana  is required!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Select"
                  name="thana"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.thanaList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>
            <FormItem label="Address Line 1">
              {getFieldDecorator('addressLineOne', {
                rules: [{ required: true, message: 'Address is required!' }],
              })(
                <Input
                  size="small"
                  name="addressLineOne"
                  placeholder="Enter address Line One"
                  value={this.state.addressLineOne.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem label="Address Line 2">
              {getFieldDecorator('addressLineTwo', {
                rules: [{ required: false, message: 'Please input address line two!' }],
              })(
                <Input
                  size="small"
                  name="addressLineTwo"
                  placeholder="Enter address Line two"
                  value={this.state.addressLineTwo.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem label="Status">
              {getFieldDecorator('recordStat', {
                rules: [{ required: true, message: 'Please select status!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Select"
                  name="recordStat"
                  value={this.state.recordStat.value}
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.referenceStatusItems.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem
              wrapperCol={{
                offset: 10,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }

  // Validation Functions
  validateAgentname = (agentName) => {
    if (agentName.length < AGENTNAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Agent name is too short (Minimum ${AGENTNAME_MIN_LENGTH} characters needed.)`,
      };
    } else if (agentName.length > AGENTNAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `Agent name is too long (Maximum ${AGENTNAME_MAX_LENGTH} characters allowed.)`,
      };
    } else {
      return {
        validateStatus: null,
        errorMsg: null,
      };
    }
  };

  validateEmail = (email) => {
    if (!email) {
      return {
        validateStatus: 'error',
        errorMsg: 'Email may not be empty',
      };
    }

    const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
    if (!EMAIL_REGEX.test(email)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Email not valid',
      };
    }

    if (email.length > EMAIL_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validatePhone = (phoneNumber) => {
    if (!phoneNumber) {
      return {
        validateStatus: 'error',
        errorMsg: 'Phone number may not be empty',
      };
    }

    const NUMBER_REGEX = RegExp('^[0-9]');
    if (!NUMBER_REGEX.test(phoneNumber)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Phone number not valid',
      };
    }

    if (phoneNumber.length > PHONE_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Phone number is too long (Maximum ${PHONE_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateMobile = (mobileNumber) => {
    if (!mobileNumber) {
      return {
        validateStatus: 'error',
        errorMsg: 'Mobile number may not be empty',
      };
    }

    const NUMBER_REGEX = RegExp('^[0-9]');
    if (!NUMBER_REGEX.test(mobileNumber)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Mobile number not valid',
      };
    }

    if (mobileNumber.length > MOBILE_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Mobile number is too long (Maximum ${MOBILE_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateAgentnameAvailability() {
    // First check for client side errors in username
    const agentnameValue = this.state.agentName.value;
    const agentnameValidation = this.validateAgentname(agentnameValue);

    if (agentnameValidation.validateStatus === 'error') {
      this.setState({
        agentName: {
          value: agentnameValue,
          ...agentnameValidation,
        },
      });
      return;
    }

    this.setState({
      agentName: {
        value: agentnameValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/admin/v1/agents/check/name?agentName=', agentnameValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            agentName: {
              value: agentnameValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            agentName: {
              value: agentnameValue,
              validateStatus: 'error',
              errorMsg: 'This Agent name is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            agentName: {
              value: agentnameValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }

  validateEmailAvailability() {
    // First check for client side errors in email
    const emailValue = this.state.email.value;
    const emailValidation = this.validateEmail(emailValue);

    if (emailValidation.validateStatus === 'error') {
      this.setState({
        email: {
          value: emailValue,
          ...emailValidation,
        },
      });
      return;
    }

    this.setState({
      email: {
        value: emailValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/admin/v1/agents/check/email?email=', emailValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            email: {
              value: emailValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            email: {
              value: emailValue,
              validateStatus: 'error',
              errorMsg: 'This Email is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            email: {
              value: emailValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }

  validateAgentPhoneNumberAvailability() {
    // First check for client side errors in username
    const agentphoneValue = this.state.phoneNumber.value;
    const agentphoneValidation = this.validatePhone(agentphoneValue);

    if (agentphoneValidation.validateStatus === 'error') {
      this.setState({
        phoneNumber: {
          value: agentphoneValue,
          ...agentphoneValidation,
        },
      });
      return;
    }

    this.setState({
      phoneNumber: {
        value: agentphoneValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/admin/v1/agents/check/phone?phoneNumber=', agentphoneValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            phoneNumber: {
              value: agentphoneValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            phoneNumber: {
              value: agentphoneValue,
              validateStatus: 'error',
              errorMsg: 'Agent phone number is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            phoneNumber: {
              value: agentphoneValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }

  validateAgentMobileNumberAvailability() {
    // First check for client side errors in username
    const agentmobileValue = this.state.mobileNumber.value;
    const agentmobileValidation = this.validateMobile(agentmobileValue);

    if (agentmobileValidation.validateStatus === 'error') {
      this.setState({
        mobileNumber: {
          value: agentmobileValue,
          ...agentmobileValidation,
        },
      });
      return;
    }

    this.setState({
      mobileNumber: {
        value: agentmobileValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/admin/v1/agents/check/mobile?mobileNumber=', agentmobileValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            mobileNumber: {
              value: agentmobileValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            mobileNumber: {
              value: agentmobileValue,
              validateStatus: 'error',
              errorMsg: 'Agent mobile number is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            mobileNumber: {
              value: agentmobileValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }
}
