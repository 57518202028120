import React, { Component, useState } from 'react';
import { getRecordById, updateRecord, getRecords } from '@app/utils/APIUtils';
import { Form, Input, Select, Descriptions, Tag } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();

  return (
    <EditPayment
      {...props}
      navigation={navigate}
      loading={loading}
      setLoading={setLoading}
      params={params}
      form={form}
    />
  );
}

class EditPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      paymentId: this.props.params.id,
      serviceList: [],
      packageList: [],
      paymentModeList: [],
      id: {
        value: '',
      },
      address: '',
      customerName: '',
      mobileNumber: '',
      totalAmount: '',
      paidAmount: '',
      amountInfo: {},
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    //this.handleServiceChange = this.handleServiceChange.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise1, promise3, promise4, promise5 /* , promise6 */;

    promise5 = getRecords('/domain/data/paymentmode/v1/all');
    //promise6 = getRecords('/setup/v1/services/all');

    this.props.setLoading(true);

    promise1 = getRecordById('/customer/v1/payment/', this.state.paymentId);
    promise1.then((res) => {
      this.props.form.setFieldsValue({
        customerId: res.CustomerBean.id,
        serviceId: res.ServiceBean == null ? null : res.ServiceBean.id,
        packageId: res.PackageBean == null ? null : res.PackageBean.id,
        others: res.others,
        paymentMode: res.PaymentModeBean.id,
        amount: res.Amount,
        lessAdjustment: res.LessAdjustment,
        remarks: res.Remarks,
        voucherNo: res.VoucherNo,
      });

      this.setState({
        customerName: res.CustomerBean.CustName,
        mobileNumber: res.CustomerBean.MobileNo,
        address: res.CustomerBean.AddressLine1,
      });

      promise3 = getRecordById('/customer/v1/payment/calculate/amount/', res.CustomerBean.id);
      promise3
        .then((response) => {
          this.setState({
            amountInfo: response,
          });
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
          this.props.setLoading(false);
        });

      /* promise6
        .then((response) => {
          this.setState({
            serviceList: response.map((item) => ({ value: item.id, label: item.ServiceName })),
          });
        })
        .catch((error) => {
          if (error)
            this.setState({
              loading: false,
            });
        }); */

      /* promise4 = getRecords(`/setup/v1/packages/${res.ServiceBean.id}/service`);
      promise4
        .then((response) => {
          this.setState({
            packageList: response.map((item) => ({ value: item.id, label: item.PackageName })),
          });
          console.log(this.state.packageList);
        })
        .catch((error) => {
          if (error)
            this.setState({
              loading: false,
            });
        }); */
    });

    promise5
      .then((response) => {
        this.setState({
          paymentModeList: response.map((item) => ({ value: item.id, label: item.paymentModeName })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.props.setLoading(false);
      });

    Promise.all([promise1, promise3, promise4, promise5 /* , promise6 */]);
  }

  /* handleServiceChange = (value) => {
    try {
      getRecords(`/setup/v1/packages/${value}/service`)
        .then((response) => {
          this.props.form.resetFields(['packageId']);
          this.setState({
            packageList: response.map((item) => ({ value: item.id, label: item.PackageName })),
          });
        })
        .catch((error) => {
          if (error) this.setState({ packageList: [] });
          this.setState({
            loading: false,
          });
        });
    } catch (error) {}
  }; */

  handleSubmit(values) {
    const updateRequest = Object.assign({}, values);
    //updateRequest.referenceStatusId  = 111;
    console.log('updateRequest => ' + JSON.stringify(updateRequest));

    updateRecord(this.state.paymentId, updateRequest, '/customer/v1/payment/')
    .then((response) => {
      if (response)
        notificationController.success({
          message: 'Payment updated.',
        });
      this.props.navigation('/customer/payment/list');
    })
    .catch((error) => {
      notificationController.error({
        message: 'Failed! ' + error.message,
      });
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigation('/customer/payment/list');
  }

  render() {
    const { amountInfo } = this.state;
    const numberFormat = (value) => new Intl.NumberFormat('en-IN').format(value);

    return (
      <BaseCard id="validation form" title="Edit Payment" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              form={this.props.form}
              onFinish={this.handleSubmit}
              className="edit-payment-form"
              name="editPayment"
              labelCol={{ span: 7 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem
                label="Customer Id"
                name="customerId"
                rules={[
                  {
                    required: true,
                    message: 'Customer id is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter customer id" disabled />
              </FormItem>

              <FormItem 
                label="Service" 
                name="serviceId" 
                hidden={true} 
                className="ant-latest-form-item"
              >
                {/* <-- Pass hidden serviceId --> */}
                <Input size="small" placeholder="Enter service id" readOnly={true} />
              </FormItem>

              <FormItem 
                label="Package" 
                name="packageId" 
                hidden={true} 
                className="ant-latest-form-item"
              >
                {/* <-- Pass hidden packageId --> */}
                <Input size="small" placeholder="Enter package id" readOnly={true} />
              </FormItem>

              <FormItem
                label="Voucher No"
                name="voucherNo"
                rules={[
                  {
                    required: true,
                    message: 'Voucher no is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter voucher no" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              {/* <FormItem label="Service">
                {getFieldDecorator('serviceId', {
                  rules: [{ required: true, message: 'Service is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select Service"
                    size="small"
                    name="serviceId"
                    onChange={this.handleServiceChange}
                    optionFilterProp="children"
                    onSearch={onSearch}
                  >
                    {this.state.serviceList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Package">
                {getFieldDecorator('packageId', {
                  rules: [{ required: true, message: 'Package is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select package"
                    size="small"
                    name="packageId"
                    optionFilterProp="children"
                    onSearch={onSearch}
                  >
                    {this.state.packageList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem> */}

                <FormItem
                  label="Mode of Payment"
                  name="paymentMode"
                  rules={[
                    {
                      required: true,
                      message: 'Payment mode is required!',
                    },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Select
                    showSearch
                    placeholder="Select payment mode"
                    size="small"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.paymentModeList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>

                <FormItem
                  label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: 'Amount is required!',
                    },
                    {
                      pattern: /^-?\d*$/,
                      message: 'Only number allowed',
                    },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Input addonAfter="BDT" size="small" placeholder="Enter amount" onKeyDown={(e) => handleEnter(e)} />
                </FormItem>

                <FormItem
                  label="Less / Adjustment"
                  name="lessAdjustment"
                  rules={[
                    {
                      required: false,
                      message: 'Less/Adjustment is required!',
                    },
                    {
                      pattern: /^-?\d*$/,
                      message: 'Only number allowed',
                    },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Input
                    addonAfter="BDT"
                    size="small"
                    placeholder="Enter Less/Adjustment amount"
                    onKeyDown={(e) => handleEnter(e)}
                  />
                </FormItem>

                <FormItem label="Others" name="others" className="ant-latest-form-item">
                  <Input placeholder="Enter others" size="small" onKeyDown={(e) => handleEnter(e)} />
                </FormItem>

                <FormItem label="Remarks" name="remarks" className="ant-latest-form-item">
                  <Input size="small" placeholder="Enter remarks" onKeyDown={(e) => handleEnter(e)} />
                </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 7,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm}/>
              </FormItem>
            </Form>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <Descriptions title="Customer Payment Info" bordered size="middle">
              <Descriptions.Item label="Customer Name" span={3}>
                {this.state.customerName}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile Number" span={3}>
                {this.state.mobileNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={3}>
                {this.state.address}
              </Descriptions.Item>
              <Descriptions.Item label="Total Payable Amount" span={3}>
                <Tag color="green" key={amountInfo.packageAmount} style={{ fontSize: 15, fontWeight: 600 }}>
                  {'৳'.concat(' ').concat(numberFormat(amountInfo.packageAmount))}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Total Paid Amount" span={3}>
                <Tag color="blue" key={amountInfo.paidAmount} style={{ fontSize: 15, fontWeight: 600 }}>
                  {'৳'.concat(' ').concat(numberFormat(amountInfo.paidAmount))}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Total Due Amount" span={3}>
                <Tag color="red" key={amountInfo.dueAmount} style={{ fontSize: 15, fontWeight: 600 }}>
                  {'৳'.concat(' ').concat(numberFormat(amountInfo.dueAmount))}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}
