import { css } from 'styled-components';

export const resetCss = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 1rem;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 1.25rem;
    border: 0.375rem solid transparent;
    background-clip: content-box;
  }

  body {
    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.6;
    font-weight: 400;
    font-family: open sans, Helvetica, Arial, sans-serif;
    background-color: #fafafa;
  }

  img {
    display: block;
  }

  span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
    background-color: transparent !important;
  }

  span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg > input {
    background-color: transparent !important;
  }

  thead.ant-table-thead {
    background-color: var(--primary-color);
  }

  thead.ant-table-thead > tr > th.ant-table-cell {
    color: var(--background-color);
    font-weight: 700;
  }

  .ant-row.ant-legacy-form-item {
    margin-bottom: 10px !important;
  }

  .ant-card-head-wrapper {
    height: 40px;
  }

  .ant-descriptions.ant-descriptions-bordered {
    display: contents;
  }

  th.ant-descriptions-item-label {
    font-size: 13px;
    font-weight: 600;
    color: #8c8c8c;
  }

  .ant-descriptions-item-content {
    font-size: 13px;
    font-weight: 600;
    color: #141414;
  }

  .ant-legacy-form-item-label > label {
    color: var(--primary-color);
    font-weight: 600;
  }

  .lonyFo .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border: 2px solid var(--border-base-color);
  }
  .ant-select {
    font-size: 14px;
  }

  .ant-select-item-option-content {
    font-size: 14px;
  }

  .ant-input {
    font-size: 14px;
    height: 35px;
  }

  .ant-picker-input > input {
    font-size: 14px;
    height: 30px;
  }

  span.authusername {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: rgb(0 0 0 / 84%);
  }

  .ant-picker-input > input[disabled] {
    color: rgb(0 0 0 / 82%);
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 7px;
  }

  .bSirVY .ant-input-group-addon {
    display: none;
  }

  .ant-card-head-title {
    font-size: 16px !important;
  }

  .ant-modal-confirm-btns .ant-btn {
    height: 37px;
    padding: 1.4000000000000004px 11px;
  }
  .ant-modal-confirm-body .ant-modal-confirm-title {
    font-weight: 600;
    font-size: 17px;
  }

  .ant-pagination-item-active {
    font-weight: 500;
    background: #c0daf2 !important;
    border-color: var(--ant-primary-color);
  }

  #mobileNumber {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  }

  .ant-pro-card-extra {
    font-size: 25px;
  }

  .ant-pro-card .ant-pro-card-title {
    font-weight: 700 !important;
  }

  .hFyaOi {
    box-shadow: 0 2px 8px 0 rgb(111 175 235) !important;
  }

  .hdxoGZ {
    box-shadow: 0 2px 8px 0 rgb(111 175 235) !important;
  }

  .ClGUV {
    box-shadow: 0 2px 8px 0 rgb(111 175 235);
  }

  .bCBdsz {
    box-shadow: 0 2px 8px 0 rgb(111 175 235);
  }

  #RegisteredCard {
    background-color: #c1eafe;
    height: 140px;
  }

  #PregisteredCard {
    background-color: #dff6e6;
    height: 140px;
  }

  #CustomerCard {
    background-color: #fff4de;
    height: 140px;
  }

  #AgentCard {
    background-color: #ffe7ea;
    height: 140px;
  }

  #AgentCard .ant-card-head {
    background-color: #c95562;
  }

  #CustomerCard .ant-card-head {
    background-color: #c88f1b;
  }

  #PregisteredCard .ant-card-head {
    background-color: #41bf6d;
  }

  #RegisteredCard .ant-card-head {
    background-color: #1b83ba;
  }

  #Hajj .ant-card-body {
    background-color: #fbe8fe;
  }

  #Hajj .ant-card-head {
    background-color: #77068a;
    color: #ffffff;
  }

  #Umrah .ant-card-head {
    background-color: #068b7a;
    color: '#FFFFFF';
  }
  #Umrah .ant-card-body {
    background-color: #def4f2;
  }

  .table-row-even {
    background-color: aliceblue;
  }

  .ant-btn.ant-btn-default {
    border-radius: 0px !important;
    font-weight: 500;
  }

  .ant-btn.ant-btn-primary {
    border-radius: 0px !important;
    font-weight: 500;
  }

  .ant-btn.ant-btn-ghost {
    border-radius: 0px !important;
    font-weight: 500;
  }

  .ant-btn.ant-btn-info {
    border-radius: 0px !important;
    font-weight: 600;
  }

  th.ant-table-cell.ant-table-selection-column {
    background-color: currentColor;
  }

  .ant-form-item-label > label {
    color: var(--primary-color);
    font-weight: 600;
  }

  .ant-form-item.ant-latest-form-item{
    margin: 0 0 0px
  }
`;
